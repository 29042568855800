import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@kildenconfig/api-config';
import { AppConfig } from '@kildenconfig/app.config';
import { LayerStylesConst } from '@kildenshared/constants/styles/layer-styles.const';
import { LayerConfigInterface } from '@kildenshared/interfaces/layer-config.interface';
import { LayerConfigsType } from '@kildenshared/interfaces/layer-configs.type';
import { Map as OlMap } from 'ol';
import Feature from 'ol/Feature';
import GeoJSON, { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import { Options as VectorLayerOptions } from 'ol/layer/BaseVector';
import ImageLayer from 'ol/layer/Image';
import VectorLayer from 'ol/layer/Vector';
import { bbox } from 'ol/loadingstrategy';
import ImageWMS from 'ol/source/ImageWMS';
import VectorSource, { Options as VectorSourceOptions } from 'ol/source/Vector';
import { take, tap } from 'rxjs';
import Swal from 'sweetalert2';

export class ThemeLayers {
  themeLayersList: (ImageLayer<ImageWMS> | VectorLayer<VectorSource>)[] = [];

  private _httpClient!: HttpClient;
  // Internal list of failing layers in this collection
  private _layersWithErrors: string[] = [];
  private _map: OlMap | undefined;
  private _sourceUrlExceptionMap = new Map<string, string | undefined>([
    [AppConfig.IDBOD_FOREST_ROADS_WFS, undefined],
    [AppConfig.IDBOD_NIB_2, ApiConfig.URL_GEONORGE_WMS_NIB_PROJECTS],
    [AppConfig.IDBOD_NIB_SATELLITE, ApiConfig.URL_GEONORGE_WMS_NIB_PROJECTS],
    [AppConfig.WILDLIFE_POINTSLAYER_ID, undefined],
    [AppConfig.WILDLIFE_TRACKSLAYER_ID, undefined],
  ]);

  constructor(options: { layerConfigs: LayerConfigsType; map?: OlMap; httpClient?: HttpClient; errorService?: any }) {
    if (options.httpClient) {
      this._httpClient = options.httpClient;
    }
    if (options.map) {
      this._map = options.map;
    }

    for (const l in options.layerConfigs) {
      if (
        options.layerConfigs[l].type === 'wms' &&
        !options.layerConfigs[l].background &&
        options.layerConfigs[l].singleTile
      ) {
        // console.log('layer[%s]:', l, layers[l].singleTile);
        const newLayer = this._createImageWMSLayer(l, options.layerConfigs[l]);
        this.themeLayersList.push(newLayer);
      } else if (
        (options.layerConfigs[l].type === 'api' || options.layerConfigs[l].type === 'wfs') &&
        !options.layerConfigs[l].background
      ) {
        const newLayer = this._createWFSLayer(l, options.layerConfigs[l]);
        // console.log(`newLayer`, l, newLayer.getSource()?.getFeatures().length);
        this.themeLayersList.push(newLayer);
      }
    }
  }

  addLayer<T extends VectorLayer<VectorSource> | ImageLayer<ImageWMS>>(layer: T): T {
    this.themeLayersList.push(layer);
    return this.themeLayersList[this.themeLayersList.length - 1] as T;
  }

  /**
   * Remove the id from internal layer list.
   * @param  id               Layer id
   * @return    void
   */
  clearFromErrorList(id: string) {
    this._removeErrorLayer(id);
  }

  /**
   * Find and return the layer with id
   * @param  id               Id of the layer|
   * @return    Openlayers layer if id is found, null otherwise
   */
  find(id: string): ImageLayer<ImageWMS> | VectorLayer<VectorSource> | null {
    for (let l = 0; l < this.themeLayersList.length; l++) {
      if (this.themeLayersList[l].get('id') === id) {
        return this.themeLayersList[l];
      }
    }
    return null;
  }

  setMap(map: OlMap | undefined): void {
    this._map = map;
  }

  private _addErrorLayer(id: string) {
    if (this._layersWithErrors.indexOf(id, 0) < 0) {
      this._layersWithErrors.push(id);
      return true;
    }
    return false;
  }

  private _createImageWMSLayer(idBod: string, config: Partial<LayerConfigInterface>): ImageLayer<ImageWMS> {
    const instanceThis = this;
    let styles = '';
    if (config.wmsUrl?.toLowerCase().includes('styles=')) {
      styles = '' + new URL(config.wmsUrl.toLowerCase()).searchParams.get('styles');
    }

    const sourceConfig = {
      url: this._getSourceUrl(idBod, config),
      attributions: config.attribution,
      params: {
        FORMAT: 'image/' + config.format,
        LAYERS: config.wmsLayers,
        VERSION: config.wms_version,
        STYLES: styles,
      },
      projection: 'EPSG:3857', // needed on 3D
    };
    const newLayerSource = new ImageWMS(sourceConfig);
    // set the config as property of the source
    newLayerSource.set('config', config);

    // function to call on every loadend for layer
    newLayerSource.on('imageloadend', function (e) {
      const config = e.target.get('config');
      // Clear this layerid from the list of failing layers
      instanceThis._removeErrorLayer(config.label);
    });
    newLayerSource.on('imageloaderror', function (e) {
      const config = e.target.get('config');

      // Early return if flybilder - ref. Jira KILDEN3-443
      if (config.idBod === 'norgeibilder_2') {
        return;
      }

      // Add this layerid to the list of failing layers
      if (instanceThis._addErrorLayer(config.label)) {
        Swal.fire({
          title: ApiConfig.defaultErrorHeader,
          html:
            'Henting av temakartet <b>' + instanceThis._getFailingLayersText() + '</b> feilet: <BR> Prøv igjen senere!',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    });

    const layerConfig = {
      properties: { id: idBod },
      source: newLayerSource,
      opacity: config.opacity || AppConfig.DEFAULT_LAYER_OPACITY,
      visible: false,
      zIndex: AppConfig.ZINDEX_THEMELAYERS,
      maxResolution: config.maxResolution,
      minResolution: config.minResolution,
    };
    return new ImageLayer(layerConfig);
  }

  private _createWFSLayer(idBod: string, config: Partial<LayerConfigInterface>): VectorLayer<VectorSource> {
    const outerThis = this;
    const projCode = outerThis._map?.getView().getProjection().getCode();
    const formatGeoJson = new GeoJSON();

    let urls: { url: string; name: string }[] = [];

    const sourceConfig: VectorSourceOptions = {
      attributions: config.attribution,
      loader:
        config.type === 'api'
          ? undefined
          : function (this: any, extent, resolution, projection) {
              let allFeatures: Feature<Geometry>[] = [];
              urls = [];

              // clear existing features from src
              if (this.getFeatures().length > 0) {
                (this as VectorSource<Feature<Geometry>>).clear();
                // (this as VectorSource<Geometry>).refresh();
              }

              // fetch using new bbox
              config.wmsLayers?.split(',').forEach(layerName => {
                let wfsUrl = config.wmsUrl + '&typeName=' + layerName;

                if (outerThis._map) {
                  if (projCode?.length) {
                    wfsUrl += `&srsname=${projCode}`;
                    const bbox = outerThis._map.getView().calculateExtent(outerThis._map.getSize());
                    if (bbox?.length) {
                      wfsUrl += `&bbox=${bbox.toString()},${projCode}`;
                    }
                  }
                }
                urls.push({ url: wfsUrl, name: layerName });
              });

              urls.forEach(urlObj => {
                outerThis._httpClient
                  .get<GeoJSONFeatureCollection>(urlObj.url)
                  .pipe(
                    tap(response => {
                      // console.log(`response ${urlObj.name}`, response.features.length);
                      const features = formatGeoJson.readFeatures(response) as Feature<Geometry>[];

                      features.forEach(feature => {
                        const id =
                          feature.get('kommunenummer') +
                          '-' +
                          feature.get('vegnummer') +
                          '-' +
                          feature.get('strekningnummer');
                        feature.setProperties({ origin: urlObj.name, id: id, idBod: config.idBod });
                        //feature.setId();
                        // (this as VectorSource<Geometry>).addFeature(feature);
                      });
                      if (features.length > 0) {
                        (this as VectorSource<Feature<Geometry>>).addFeatures(features);
                        if (!allFeatures) {
                          allFeatures = features;
                        } else {
                          allFeatures = allFeatures.concat(features);
                        }
                      }
                      // console.log(`features`, features);
                    }),
                    take(1)
                  )
                  .subscribe();
              });

              if (allFeatures) {
                (this as VectorSource<Feature<Geometry>>).addFeatures(allFeatures);
              }
              (this as VectorSource<Feature<Geometry>>).changed();
            },
      strategy: bbox,
    };

    const newVectorSource = new VectorSource(sourceConfig);
    // set the config as property of the source
    newVectorSource.set('config', config);

    const layerOptions: VectorLayerOptions<Feature, VectorSource> = {
      maxResolution: config.maxResolution,
      minResolution: config.minResolution,
      opacity: config.opacity || 1,
      source: newVectorSource,
      style: LayerStylesConst[idBod] || [],
      visible: true,
      zIndex: AppConfig.ZINDEX_THEMELAYERS + 1,
      properties: {
        altitudeMode: 'clampToGround',
        id: idBod,
        label: config.label,
        timeEnabled: config.timeEnabled,
      },
    };

    return new VectorLayer(layerOptions);
  }

  private _getFailingLayersText() {
    let failingText = '';
    for (const label of this._layersWithErrors) {
      // Add a comma if this is not first layer.
      if (failingText !== '') {
        failingText += ',';
      }
      failingText += ' ' + label;
    }
    return failingText;
  }

  private _getSourceUrl(idBod: string, config: Partial<LayerConfigInterface>): string | undefined {
    if (this._sourceUrlExceptionMap.has(idBod)) {
      if (idBod === AppConfig.IDBOD_NIB_2 && config.wmsLayers === 'ortofoto') {
        return ApiConfig.URL_GEONORGE_WMS_NIB;
      }

      if (idBod === AppConfig.IDBOD_FOREST_ROADS_WFS) {
        let forestRoadUrl = config.wmsUrl + '&typeName=skogsbilveg';

        if (this._map) {
          const projection = this._map.getView().getProjection().getCode();
          const bbox = this._map.getView().calculateExtent(this._map.getSize());
          if (projection?.length) {
            forestRoadUrl += `&srsname=${projection}`;
            if (bbox?.length) {
              forestRoadUrl += `&bbox=${bbox.toString()},${projection}`;
            }
          }
        }
        return forestRoadUrl;
      }

      return this._sourceUrlExceptionMap.get(idBod);
    }

    // Crop URL to strip params as some cause problems for OpenLayers
    return config.wmsUrl?.split('?')[0];
  }

  private _removeErrorLayer(id: string) {
    const index = this._layersWithErrors.indexOf(id, 0);
    if (index > -1) {
      this._layersWithErrors.splice(index, 1);
    }
  }
}
