<kilden3-catalog-item [catalogTree]="catalogTree" (keydown)="executeKeydown($event)">
  <ng-template class="template-container" let-item>
    <!--Start cataloglevel-->
    <button
      *ngIf="item.children?.length > 0"
      (click)="toggleFolderExpanded(item)"
      [ngClass]="{
        'indent-catalog': !item.firstLevel,
        'indent-catalog-first-level': item.firstLevel,
      }"
      class="expand-collapse catalog-level catalog-btn"
      draggable="false"
      tabindex="-1">
      <div class="catalog-flex-item" draggable="false">
        <mat-icon
          *ngIf="!item.expanded"
          class="catalog-layer-expand-icon"
          draggable="false"
          fontSet="material-icons-outlined"
          >add_circle_outline</mat-icon
        >
        <mat-icon
          *ngIf="item.expanded"
          class="catalog-layer-expand-icon"
          draggable="false"
          fontSet="material-icons-outlined"
          >remove_circle_outline</mat-icon
        >

        <input
          *ngIf="item.parentCheckbox"
          (click)="toggleLayerChild(item, item.children, $event)"
          [checked]="item.selectedOpen"
          draggable="false"
          id="{{ item.id }}"
          tabindex="-1"
          title="Velg alle lag"
          type="checkbox" />
        {{ item.label }}
      </div>
      <a
        *ngIf="item.infolink"
        [href]="item.infolink"
        class="catalog-info-btn info-icon"
        draggable="false"
        mat-icon-button
        target="_blank">
        <mat-icon draggable="false" fontSet="material-icons-outlined">info</mat-icon>
      </a>
    </button>
    <!--End cataloglevel-->

    <!--Start layerlevel-->
    <kilden3-catalog-layer *ngIf="item.category === 'layer'" [layer]="item"></kilden3-catalog-layer>
    <!--End layerlevel-->
  </ng-template>
</kilden3-catalog-item>
