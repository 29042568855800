import { Component, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppConfig } from '@kildenconfig/app.config';
import { SearchService } from '@kildencore/services/data/search.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapService } from '@kildencore/services/map.service';
import { liveSearch, moveCursorToStart } from '@kildencore/tools';
import { SearchDataItem } from '@kildenshared/types/search/search-data-item.type';
import { SearchMergedData } from '@kildenshared/types/search/search-merged-data.type';
import { SearchResponseData } from '@kildenshared/types/search/search-response-data.type';
import Feature from 'ol/Feature';
import OlFormatGeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import Overlay from 'ol/Overlay';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import OlStyleFill from 'ol/style/Fill';
import OlStyleStroke from 'ol/style/Stroke';
import OlStyleStyle from 'ol/style/Style';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

const BORDER_SONE_ID = 'SoneBorderVectorLayer';

@Component({
  selector: 'kilden3-sonegrense',
  templateUrl: './sonegrense.component.html',
  styleUrls: ['./sonegrense.component.css'],
})
export class SonegrenseComponent implements OnDestroy {
  minLengthTerm = 1;
  stateForm = this._formBuilder.group({
    soneSearchTerm: '',
    orgnrTerm: '',
  });

  constructor(
    private _formBuilder: FormBuilder,
    private ss: SearchService,
    private ms: MapService,
    private kss: KildenStateService
  ) {
    this.ms.createPopup();
  }

  ngOnDestroy() {
    this.removeSoneSearchFromMap();
    this.setTextInKildenHeader('');
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public currentTitle = '';
  public topic = '';
  public isLandbrukseiendom: boolean = false;
  private propertyList: Array<SearchDataItem> = [];
  public soneKjott = '';
  public soneMelk = '';
  public soneKommune = '';
  public orgNrValue = '';
  public north = 0;
  public east = 0;
  public koordsys = 0;
  private subscriptions: Subscription[] = [];

  private getEpsg() {
    return this.ms.getMap().getView().getProjection().getCode();
  }

  private getTopic() {
    this.subscriptions.push(this.kss.topic$.subscribe(d => (this.topic = d)));
  }

  private stateGroupOptionsData = this.stateForm.valueChanges.pipe(
    liveSearch((value: SoneSearchValue) => {
      this.getTopic();
      return this.ss.getAll(value.soneSearchTerm, this.getEpsg(), this.topic);
    })
  );

  stateGroupOptions = this.stateGroupOptionsData.pipe(
    map((data: SearchResponseData) => {
      return this.processLocationData(data);
    })
  );

  /**
   *
   *
   * @private Removes all with objecttype FYLKE
   * @param {*} arr Array with all the results
   * @return { the array without fylker}
   * @memberof SonegrenseComponent
   */
  private removeFylker(arr: any) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i].attrs.objektType === 'FYLKE') {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  /**
   *
   *
   * @private  Sort the data for the search
   * @param {ResponseData} data
   * @return {*}  {MergedData[]}
   * @memberof SonegrenseComponent
   */
  private processLocationData(data: SearchResponseData): SearchMergedData[] {
    const { location, fylkeKommune, property, reindeer } = data;

    let mergedData: SearchMergedData[] = [];

    //const onlyKommune = this.removeFylker(fylkeKommune);

    if (fylkeKommune && fylkeKommune.length && typeof fylkeKommune !== 'string') {
      const fylkeKommuneItems = [
        {
          category: 'Kommune',
          data: fylkeKommune.map((d: any) => ({
            catType: 'countyMunicipality',
            title: d.attrs.title,
            id: d.id,
            type: d.attrs.objektType,
          })),
        },
      ];
      mergedData = [...mergedData, ...fylkeKommuneItems];
    }

    if (property && property.length && typeof property !== 'string') {
      const properties = [
        {
          category: 'Adresse/matrikkel',
          data: property
            .filter((e: any) => !(e.attrs.objektType === 'VEG' && !e.attrs.lat && !e.attrs.lon))
            .map((p: any) => ({
              catType: 'property',
              title: p.attrs.title.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (l: string) => l.toUpperCase()),
              municipalityNumb: p.attrs?.municipalityNumb || '0',
              gnr: p.attrs?.gnr || 0,
              bnr: p.attrs?.bnr || 0,
              fnr: p.attrs?.fnr || 0,
              x: (Math.round(p.attrs.lat * 100) / 100) as number,
              y: (Math.round(p.attrs.lon * 100) / 100) as number,
              origin: p.attrs?.origin.toLowerCase() || '',
              objektType: p.attrs?.objektType.toLowerCase() || '',
            })),
        },
      ];
      mergedData = [...mergedData, ...properties];
    }

    return mergedData;
  }

  private setTextInKildenHeader(text: string) {
    let landbrukseiendomElement: any = null;
    landbrukseiendomElement = document.getElementById('showLandsbrukseiendomText');
    if (landbrukseiendomElement) {
      landbrukseiendomElement.innerText = text;

      text
        ? landbrukseiendomElement.removeAttribute('hidden')
        : landbrukseiendomElement.setAttribute('hidden', 'hidden');
    }
  }
  /**
   *
   *
   * @private Get properties data and add driftsenter
   * @param {DataItem} selectedData
   * @param {Boolean} [isLandbrukseiendom=false]
   * @param {0} numberOfProperties
   * @memberof SonegrenseComponent
   */
  private getData(selectedData: SearchDataItem, isLandbrukseiendom: Boolean = false, numberOfProperties: 0) {
    this.subscriptions.push(
      this.ss
        .getProperty(
          selectedData.municipalityNumb as string,
          selectedData.gnr as number,
          selectedData.bnr as number,
          selectedData.fnr as number,
          this.getEpsg()
        )
        .subscribe(data => {
          if (isLandbrukseiendom) {
            this.propertyList.push(data);
          }
          if (numberOfProperties === this.propertyList.length && isLandbrukseiendom) {
            this.removeSoneSearchFromMap();
            this.showFeaturesInMap(this.propertyList, true);
            this.setTextInKildenHeader('Landbrukseiendom');
          } else {
            this.removeSoneSearchFromMap();
            this.showFeaturesInMap(data, false);
            this.setTextInKildenHeader('Grunneiendom');
          }
          this.getDriftssenter(selectedData);
        })
    );
  }
  /**
   *
   *
   * @private Show the results in the map
   * @param {*} data
   * @param {boolean} [isLandbrukseiendom=false]
   * @memberof SonegrenseComponent
   */
  private showFeaturesInMap(data: any, isLandbrukseiendom: boolean = false) {
    const map = this.ms.getMap();
    const newSoneLayer = this.createSoneJsonLayer();
    //this.removeSoneSearchFromMap();
    let mainSource: any = null;
    map.addLayer(newSoneLayer);

    if (data?.features && data?.features.length > 0) {
      const source = newSoneLayer.getSource();
      const features = new OlFormatGeoJSON().readFeatures(data) as Feature<Geometry>[];
      source?.addFeatures(features);
      mainSource = source;
    }
    if (isLandbrukseiendom && data.length > 0) {
      const source = newSoneLayer.getSource();
      data.forEach((property: any) => {
        if (property?.features && property?.features.length > 0) {
          const features = new OlFormatGeoJSON().readFeatures(property) as Feature<Geometry>[];
          source?.addFeatures(features);
        }
      });
      mainSource = source;
    }

    if (mainSource) {
      this.ms.zoomToExtent(mainSource.getExtent());
    }
  }
  /**
   *
   *  Clear the search field in sonegrenser tool
   * @memberof SonegrenseComponent
   */
  clearSoneSearch() {
    this.stateForm.get('soneSearchTerm')?.setValue('');
    this.currentTitle = '';
    this.soneKommune = '';
    this.removeSoneSearchFromMap();
    moveCursorToStart('sonegrense-input-field');
    this.setTextInKildenHeader('');
  }
  /**
   *
   * Clear the orgnr field in sonegrenser tool
   * @memberof SonegrenseComponent
   */
  clearOrgSearch() {
    this.stateForm.get('orgnrTerm')?.setValue('');
    this.currentTitle = '';
    this.soneKommune = '';
    this.removeSoneSearchFromMap();
    moveCursorToStart('orgnr-input-field');
    this.setTextInKildenHeader('');
    this.propertyList = [];
  }

  customStopPropagation(e: KeyboardEvent) {
    if (e.key === ' ') {
      e.stopPropagation();
    }
  }
  /**
   *
   *
   * @private Remove layers from map
   * @memberof SonegrenseComponent
   */
  private removeSoneSearchFromMap(): void {
    this.ms.removePopup();

    const map = this.ms.getMap();
    const marker = map?.getOverlayById('searchSoneInfomarker');
    if (marker) {
      this.ms.getMap().removeOverlay(marker);
    }

    map
      ?.getLayers()
      .getArray()
      .slice()
      .forEach(function (layer) {
        if (layer.get('id') === BORDER_SONE_ID) {
          map.removeLayer(layer);
        }
      });
  }

  getProperties(data: SearchDataItem) {
    data.checkProperties = true;
  }

  displayFn(option: SearchDataItem): string {
    return option && option.title ? option.title : '';
  }
  /**
   *
   * Get soner for a municipality
   * @param {number} soneKomNr
   * @param {string} soneKomNavn
   * @memberof SonegrenseComponent
   */
  getSonerKommune(soneKomNr: number, soneKomNavn: string) {
    this.subscriptions.push(
      this.ss.getSonerKommune(soneKomNr).subscribe((sonedata: any) => {
        this.soneKjott = sonedata.sone_kjott;
        this.soneMelk = sonedata.sone_melk;
        this.soneKommune = soneKomNavn;
      })
    );
  }
  /**
   *
   * Get the point of the driftssenter and show it in the map
   * @param {*} property
   * @memberof SonegrenseComponent
   */
  getDriftssenter(property: any) {
    this.soneKommune = '';
    this.subscriptions.push(
      this.ss.driftsSenter(property).subscribe((driftssenterdata: any) => {
        if (driftssenterdata.length > 0) {
          const komNr = driftssenterdata[0].komnr;
          const gNr = driftssenterdata[0].gnr;
          const bNr = driftssenterdata[0].bnr;
          const epsg = this.getEpsg();
          let coords = [driftssenterdata[0].koordOst, driftssenterdata[0].koordNord];

          coords = transform(coords, 'EPSG:25833', epsg);

          const driftSenterLocation = {
            id: BORDER_SONE_ID,
            catType: 'location',
            title: 'Driftssenter: ' + komNr + '-' + gNr + '/' + bNr,
            x: Math.round(coords[1]),
            y: Math.round(coords[0]),
          };

          this.soneShowLocation(driftSenterLocation, false);
        }
      })
    );
  }
  /**
   *
   * Changes in the search input
   * @param {DataItem} selectedData
   * @memberof SonegrenseComponent
   */
  onChange(selectedData: SearchDataItem) {
    if (selectedData.type === 'KOMMUNE') {
      this.getSonerKommune(Number(selectedData.id), selectedData.title);
    }

    moveCursorToStart('sonegrense-input-field');
    this.currentTitle = selectedData.title;

    if (selectedData.catType === 'location') {
      this.soneShowLocation(selectedData, true);
    }

    if (selectedData.catType === 'countyMunicipality') {
      if (selectedData.id && selectedData.type) {
        this.subscriptions.push(
          this.ss.getMunicipalityCountyBorder(this.getEpsg(), selectedData.id, selectedData.type).subscribe(data => {
            this.removeSoneSearchFromMap();
            this.showFeaturesInMap(data);
          })
        );
      }
    }

    if (selectedData.catType === 'property') {
      if (
        selectedData.origin === 'matrikkel' &&
        (selectedData.objektType === 'eiendom' || selectedData.objektType === 'matrikkeladresse')
      ) {
        if (
          selectedData.municipalityNumb !== undefined &&
          selectedData.gnr !== undefined &&
          selectedData.bnr !== undefined &&
          selectedData.fnr !== undefined
        ) {
          if (selectedData.checkProperties) {
            this.propertyList = [];
            this.isLandbrukseiendom = false;
            this.subscriptions.push(
              this.ss
                .getProperties(selectedData.municipalityNumb, selectedData.gnr, selectedData.bnr, selectedData.fnr)
                .subscribe(data => {
                  if (data.length) {
                    this.isLandbrukseiendom = true;
                    data.forEach((property: any) => {
                      property.municipalityNumb = property.knr;
                      this.getData(property, this.isLandbrukseiendom, data.length);
                    });
                  } else {
                    this.getData(selectedData, this.isLandbrukseiendom, 0);
                  }
                })
            );
          } else {
            this.getData(selectedData, this.isLandbrukseiendom, 0);
          }
        }
      } else this.soneShowLocation(selectedData, true);
    }
  }
  /**
   *
   *
   * @private Create and return vectorlayer
   * @return {*}
   * @memberof SonegrenseComponent
   */
  private createSoneJsonLayer(): VectorLayer<VectorSource> {
    const styles = [
      new OlStyleStyle({
        stroke: new OlStyleStroke({
          color: 'rgba(255, 80, 80, 1)',
          width: 3,
        }),
        fill: new OlStyleFill({
          color: 'rgba(255, 100, 50, 0)',
        }),
      }),
    ];

    const vectorLayer: VectorLayer<VectorSource> = new VectorLayer({
      properties: { altitudeMode: 'clampToGround' },
      zIndex: AppConfig.ZINDEX_SEARCH,
      source: new VectorSource<Feature<Geometry>>({ features: [] }),
      style: () => styles,
    });
    vectorLayer.set('id', BORDER_SONE_ID, true);

    return vectorLayer;
  }
  /**
   *
   *
   * @private Show location in the map and zoom to it if zoomto param
   * @param {DataItem} selectedLocation
   * @param {boolean} zoomTo
   * @return {*}
   * @memberof SonegrenseComponent
   */
  private soneShowLocation(selectedLocation: SearchDataItem, zoomTo: boolean) {
    if (!selectedLocation.y || !selectedLocation.x) return;
    if (zoomTo) {
      const extent = [
        selectedLocation.y - 1000,
        selectedLocation.x - 1000,
        selectedLocation.y + 1000,
        selectedLocation.x + 1000,
      ];
      this.ms.zoomToExtent(extent);
    }

    this.ms.updatePopup([selectedLocation.y, selectedLocation.x + 150], selectedLocation.title);

    const elem = document.createElement('div');
    elem.setAttribute('id', 'searchSoneInfomarker');
    elem.setAttribute('class', 'location-icon');

    const map = this.ms.getMap();
    const marker = map.getOverlayById('searchSoneInfomarker');
    if (!marker) {
      const locationMarkerLayer = new Overlay({
        id: 'searchSoneInfomarker',
        position: [selectedLocation.y, selectedLocation.x],
        positioning: 'bottom-center',
        element: elem,
        className: 'location-marker-wrapper',
      });

      this.ms.getMap().addOverlay(locationMarkerLayer);
    }
  }
  /**
   *
   * Get the orgnummer and show the property
   * @memberof SonegrenseComponent
   */
  getOrgnummer() {
    this.soneKommune = '';
    this.propertyList = [];
    this.setTextInKildenHeader('');

    let orgNR = this.stateForm.get('orgnrTerm')?.value;
    orgNR = orgNR?.replace(/\s+/g, ''); //remove space

    let isnum = false;
    if (orgNR) {
      isnum = /^\d+$/.test(orgNR);
    }
    if (orgNR?.length !== 9 || !isnum) {
      Swal.fire({
        title: 'Feil verdi',
        html: 'Skriv inn organisasjonsnummer, 9 siffer.',
        icon: 'warning',
        confirmButtonText: 'OK',
        allowEnterKey: false,
      });
      return;
    }

    this.subscriptions.push(
      this.ss.getOrgnummer(orgNR).subscribe((orgNrdata: any) => {
        if (orgNrdata) {
          const komNr = orgNrdata[0].komnr;
          const gNr = orgNrdata[0].gardsnr;
          const bNr = orgNrdata[0].bruksnr;
          const fNr = orgNrdata[0].festenr;
          this.ss.getProperties(komNr, gNr, bNr, fNr).subscribe((data: any) => {
            if (data.length) {
              this.isLandbrukseiendom = true;
              data.forEach((property: any) => {
                property.municipalityNumb = property.knr;
                this.getData(property, this.isLandbrukseiendom, data.length);
              });
            } else {
              this.getData(orgNrdata[0], this.isLandbrukseiendom, 0);
            }
          });

          const epsg = this.getEpsg();
          let coords = [orgNrdata[0].koordinater.ost, orgNrdata[0].koordinater.nord];

          coords = transform(coords, 'EPSG:25833', epsg);

          const driftSenterLocation = {
            catType: 'location',
            title: 'Driftssenter: ' + komNr + '-' + gNr + '/' + bNr,
            x: Math.round(coords[1]),
            y: Math.round(coords[0]),
          };
          this.soneShowLocation(driftSenterLocation, false);
        } else {
          Swal.fire({
            title: 'Ingen data',
            html: 'Fant ikke organisasjonsnummer eller server svarer ikke',
            icon: 'warning',
            confirmButtonText: 'OK',
          });
        }
      })
    );
  }
}

type SoneSearchValue = {
  soneSearchTerm?: string | null;
  orgnrTerm?: string | null;
};
