import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { AppConfig } from '@kildenconfig/app.config';
import { DrawHelper, DrawStyleOptions } from '@kildencore/helpers/draw.helper';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapService } from '@kildencore/services/map.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { CatalogTreeItem } from '@kildenshared/components/catalog-tree/catalog-tree-item';
import { DebugToolService } from '@kildenshared/components/debug-tool/debug-tool.service';
import { ToolIdsEnum } from '@kildenshared/constants/tool-ids.enum';
import { LayerChange } from '@kildenshared/interfaces';
import { DrawType } from '@kildenshared/types/draw/draw.type';
import { Coordinate } from 'ol/coordinate';
import { click as olClick, shiftKeyOnly } from 'ol/events/condition';
import BaseEvent from 'ol/events/Event';
import Feature from 'ol/Feature';
import { Type as GeometryType } from 'ol/geom/Geometry';
import Geometry from 'ol/geom/Geometry.js';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import {
  DoubleClickZoom,
  DragAndDrop,
  DragPan,
  DragRotate,
  DragZoom,
  KeyboardPan,
  KeyboardZoom,
  Modify,
  MouseWheelZoom,
  PinchRotate,
  PinchZoom,
} from 'ol/interaction';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import Interaction from 'ol/interaction/Interaction';
import { ModifyEvent } from 'ol/interaction/Modify';
import Select, { SelectEvent } from 'ol/interaction/Select';
import Translate, { TranslateEvent } from 'ol/interaction/Translate';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import { unByKey } from 'ol/Observable';
import Overlay from 'ol/Overlay';
import VectorSource from 'ol/source/Vector';
import Icon, { Options as IconOptions } from 'ol/style/Icon';
import Style from 'ol/style/Style';
import { Subject, take, tap } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class DrawService {
  chosenTool: DrawType = 'None';
  currentArea: number = 0;
  currentLine: number = 0;
  drawLabelArr: string[] = [];
  drawLayer: VectorLayer<VectorSource> | undefined;
  drawNr = 0;
  drawType: DrawType = 'None';
  hangingDrawing: boolean = false;
  map?: Map;
  interactionDraw: Draw | undefined;
  interactionModify: Modify | undefined;
  interactionSelect: Select | undefined;
  interactionTranslate: Translate | undefined;
  optionColor: string = DrawHelper.COLOR_STROKE;
  optionColorFill: string = DrawHelper.COLOR_FILL;
  optionColorPoint: string = DrawHelper.COLOR_POINT;
  optionIconSize: number = DrawHelper.ICON_SIZE;
  optionIconSrc: string = DrawHelper.ICON_SRC;
  optionInputString: any = '';
  optionShowMeasurement = true;
  optionStrokeWidth: number = DrawHelper.STROKE_WIDTH;
  optionUseFillColor: boolean = true;
  voidMap = new Map();

  private readonly _drawArea$ = new Subject<LayerData>();
  private readonly _drawLine$ = new Subject<LayerData>();
  private readonly _drawing$ = new Subject<DrawData>();
  private readonly _onDrawAbort$ = new Subject<DrawEvent>();
  private readonly _onDrawEnd$ = new Subject<DrawEvent>();
  private readonly _onDrawStart$ = new Subject<DrawEvent>();
  private readonly _onSelectionChange$ = new Subject<{ selected: Feature[]; deselected: Feature[] }>();
  private _renderer: Renderer2 | undefined;

  // Public API observables
  drawArea$ = this._drawArea$.asObservable();
  onDrawAbort$ = this._onDrawAbort$.asObservable();
  onDrawEnd$ = this._onDrawEnd$.asObservable();
  onDrawStart$ = this._onDrawStart$.asObservable();
  onSelectionChange$ = this._onSelectionChange$.asObservable();
  drawLine$ = this._drawLine$.asObservable();
  drawing$ = this._drawing$.asObservable();

  constructor(
    private readonly _debugToolService: DebugToolService,
    private readonly _kildenStateService: KildenStateService,
    private readonly _mapService: MapService,
    private readonly _rendererFactory: RendererFactory2,
    private readonly _themeLayersService: ThemeLayersService
  ) {
    this.map = this._mapService.getMap();
    this._renderer = _rendererFactory.createRenderer(null, null);
    this.interactionSelect?.setActive(false);

    this._themeLayersService.layersChange$
      .pipe(
        tap(changes => {
          this._handleLayerChanges(changes);
        })
      )
      .subscribe();

    // When layer-order changes we must update the overlay z-indexes
    this._themeLayersService.layersReorder$
      .pipe(
        tap(layers => {
          this._handleLayersReorder(layers);
        })
      )
      .subscribe();
  }

  addDrawNr(drawLabel: boolean): number {
    this.drawNr = this.drawNr + 1;
    if (drawLabel) {
      this.drawLabelArr.push(this.drawNr.toString());
    }
    this._drawing$.next({ id: this.drawNr, layerId: this.drawLayer?.get('id') });
    return this.drawNr;
  }

  changeDrawColor(colorIn: string): void {
    if (!colorIn) {
      return;
    }

    if (colorIn.length === 4) {
      this.optionColor = colorIn;
      this.optionColorFill = colorIn;
      this.optionColorPoint = colorIn;
    } else {
      this.optionColor = DrawHelper.setTransparency(colorIn, DrawHelper.DEFAULT_STROKE_TRANSPARENCY);
      this.optionColorFill = DrawHelper.setTransparency(colorIn, DrawHelper.DEFAULT_FILL_TRANSPARENCY);
      this.optionColorPoint = DrawHelper.setTransparency(colorIn, DrawHelper.DEFAULT_POINT_TRANSPARENCY);
    }

    this._updateDrawInteraction('changeDrawColor');
  }

  changeDrawIcon(icon: string): void {
    this.optionIconSrc = icon;
    this._updateDrawInteraction('changeDrawIcon');
  }

  changeDrawIconSize(iconSize: number): void {
    this.optionIconSize = iconSize;
    this._updateDrawInteraction('changeDrawIconSize');
  }

  /**
   *  Set correct interaction active, remove others
   */
  changeInteraction(drawId: string = AppConfig.IDBOD_USER_DRAWN): void {
    // this._verifyDrawLayerInitiated(drawId);
    // this._debugToolService.logMapInteractionsToConsole('changeInteraction');
    // this._debugToolService.logMapLayersToConsole('changeInteraction');

    if (this.drawType === 'Delete' || this.drawType === 'Modify') {
      this.initSelectInteraction().setActive(true);
    } else if (this.interactionSelect) {
      this._removeInteraction(this.interactionSelect);
    }

    if (this.drawType === 'Modify') {
      this._updateSelectedFeaturesFromDrawOptions();
      this.initModifyInteraction(drawId).setActive(true);
      this.initTranslateInteraction().setActive(true);
    } else {
      if (this.interactionModify) {
        this._removeInteraction(this.interactionModify);
      }
      if (this.interactionTranslate) {
        this._removeInteraction(this.interactionTranslate);
      }
    }

    this._updateDrawInteraction('changeInteraction');
  }

  changeDrawStrokeWidth(width: number): void {
    this.optionStrokeWidth = width;
    this._updateDrawInteraction('changeDrawStrokeWidth');
  }

  /**
   * The text string used to create Text type drawings
   */
  changeDrawText(inText: string) {
    this.optionInputString = inText;
    this._updateDrawInteraction('changeDrawText');
  }

  changeMeasure(measureIn: boolean): void {
    this.optionShowMeasurement = measureIn;
    this._updateDrawInteraction('changeMeasure');
  }

  changeUseFillColor(value: boolean) {
    this.optionUseFillColor = value;
    this._updateDrawInteraction('changeUseFillColor');
  }

  /**
   * [Choose draw tool and set right geometryType for respective tool]
   */
  chooseDrawTool(inTool: DrawType, drawId: string = AppConfig.IDBOD_USER_DRAWN): void {
    // console.log(`chooseDrawTool - `, inTool);

    this._verifyDrawLayerInitiated(drawId);
    // this._debugToolService.logMapInteractionsToConsole('interactions; chooseDrawTool ' + inTool);
    // this._debugToolService.logMapLayersToConsole('layers; chooseDrawTool ' + inTool);

    this.chosenTool = inTool;
    this.drawType = inTool;

    // deselect features when switching tools
    this.clearSelected();

    this.changeInteraction(drawId);
  }

  clearSelected() {
    // console.log(`clearing selected`);
    if (this.interactionSelect) {
      const selFeatures = this.interactionSelect.getFeatures();
      selFeatures.forEach((feature: Feature<Geometry>) => {
        feature.setStyle(feature.get(DrawHelper.CACHED_STYLE_KEY));
      });
      this.interactionSelect.getFeatures().clear();
    }
  }

  /**
   * Cleanup and end interactions when drawDialog closes
   */
  endDrawInteractions() {
    this._kildenStateService.tool$.pipe(take(1)).subscribe(tool => {
      if (tool === ToolIdsEnum.DRAW) {
        this._kildenStateService.changeTool(ToolIdsEnum.INFO);
      }
    });

    this._drawLine$.next({
      id: this.drawLayer?.get('id'),
      size: this.currentLine,
    });

    this._drawArea$.next({
      id: this.drawLayer?.get('id'),
      size: this.currentArea,
    });

    // Any currently selected features should be set as unselected (remove "selected" styling)
    this.clearSelected();
    // Remove all interactions when closing draw tool, to allow 'click for objectInfo'
    this._removeDrawInteractions();

    // Any existing drawings should be stored for later use
    if (this.drawLayer) {
      const drawId = this.drawLayer.get('id');
      if (drawId === AppConfig.IDBOD_USER_DRAWN) {
        const drawSource = this.drawLayer.getSource();
        if (drawSource && drawSource.getFeatures()?.length) {
          const bankLayer = this._themeLayersService.findBankLayer(drawId);
          const tlgLayer = this._themeLayersService.findActiveLayer(drawId, false);
          if (bankLayer instanceof VectorLayer) {
            bankLayer.setSource(drawSource);
          } else if (tlgLayer instanceof VectorLayer) {
            tlgLayer.setSource(drawSource);
            // Add TL as BankLayer
            this._themeLayersService.getBankLayers()?.push(tlgLayer);
          }
        }
      }

      // Reset this since next use might be for another ID (user_drawn | areaReport | forestReport).
      this.drawLayer = undefined;
    }

    // Make sure cursor is set visible again
    this.getMap().getTargetElement()?.classList?.remove('hide-cursor');
  }

  deleteAllFeatures() {
    Swal.fire({
      title: 'Vil du slette alle tegnede objekter?',
      text: 'Sletter alle objekter!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Slett',
      cancelButtonText: 'Avbryt',
    }).then(result => {
      if (result.isConfirmed) {
        this.drawLayer?.getSource()?.clear();
        const deleteThis = this;
        this.getMap()
          .getOverlays()
          .getArray()
          .slice(0)
          .forEach(function (overlay) {
            deleteThis._mapService.removeOverlay(overlay.getId()!.toString());
          });

        // PM wants to remove from active layers if no objects remain
        this._themeLayersService.registerLayerChanges([
          {
            layerid: AppConfig.IDBOD_USER_DRAWN,
            change: {
              active: false,
            },
          },
        ]);
      }
    });
  }

  deleteSelectedFeatures(drawId: string = AppConfig.IDBOD_USER_DRAWN) {
    const features = this.interactionSelect?.getFeatures();

    const numFeat = features?.getLength() || 0;
    if (numFeat < 1) {
      Swal.fire({
        title: 'Ingen objekter valgt!',
        text: 'Du må velge hvilke objekter du vil slette i kartet!',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    } else {
      Swal.fire({
        text: numFeat === 1 ? 'Vil du slette valgt objekt?' : 'Vil du slette ' + numFeat + ' valgte objekter?',
        title: numFeat === 1 ? 'Sletter valgt objekt!' : 'Sletter valgte objekter!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Slett',
        cancelButtonText: 'Avbryt',
      }).then(result => {
        if (result.isConfirmed) {
          const idsToRemove = features?.getArray().map((f: any) => AppConfig.IDBOD_USER_DRAWN + f.getId()) || [];

          // Remove drawings
          this.removeFeatures(features);

          // Remove overlays
          this.getMap()
            ?.getOverlays()
            .getArray()
            .slice(0)
            .forEach(overlay => {
              const overlayId = overlay.getId()?.toString() || '';
              if (idsToRemove.includes(overlayId)) {
                this._mapService.removeOverlay(overlayId!);
              }
            });

          // Remove layer?
          let mapDrawLayer;
          if (drawId === AppConfig.IDBOD_USER_DRAWN) {
            mapDrawLayer = this._themeLayersService.findActiveLayer(drawId);
          } else {
            mapDrawLayer = this._mapService.getLayer(drawId);
          }

          // If no other drawings remain, deactivate layer (remove UserDrawn from ActiveLayers)
          if (mapDrawLayer?.getSource()?.getFeatures()?.length < 1) {
            this._themeLayersService.registerLayerChanges([
              {
                layerid: AppConfig.IDBOD_USER_DRAWN,
                change: {
                  active: false,
                },
              },
            ]);
          }
        }
      });
    }
  }

  getDrawIconStyle(): Style {
    return new Style({
      image: new Icon({
        color: this.optionColorPoint,
        anchor: [0.25, 0.25],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 1,
        src: this.optionIconSrc,
      } as IconOptions),
    });
  }

  getMap(): Map {
    if (!this.map) {
      this.map = this._mapService.getMap();
    }
    return this.map;
  }

  initModifyInteraction(drawId: string = AppConfig.IDBOD_USER_DRAWN): Modify {
    // If exists, return same instance
    if (this.interactionModify instanceof Modify) {
      this.interactionModify.setActive(true);
      this._enableMapInteraction(this.interactionModify);

      return this.interactionModify;
    }

    return this._makeModifyInteraction(drawId);
  }

  initSelectInteraction(): Select {
    // If exists, return same instance
    if (this.interactionSelect instanceof Select) {
      this.interactionSelect.setActive(true);
      this._enableMapInteraction(this.interactionSelect);

      return this.interactionSelect;
    }

    return this._makeSelectInteraction();
  }

  initTranslateInteraction(): Translate {
    // If exists, return same instance
    if (this.interactionTranslate instanceof Translate) {
      this.interactionTranslate.setActive(true);
      this._enableMapInteraction(this.interactionTranslate);

      return this.interactionTranslate;
    }

    return this._makeTranslateInteraction();
  }

  overlaysSetOpacity(opacity: number): void {
    this.getMap()
      .getOverlays()
      .forEach(o => {
        const el = o.getElement();
        if (el) {
          el.style.opacity = opacity.toString();
        }
      });
  }

  /**
   * OpenLayers Overlays are positionally tied to a Feature but the Overlay is not removed from map when Feature is removed.
   */
  overlaysToggle(show: boolean): void {
    this.getMap()
      .getOverlays()
      .forEach(o => {
        o.setMap(show ? this.getMap() : null);
      });
  }

  /**
   * Removes features, labels and selectfeatures.
   */
  removeFeatures(features: any) {
    const idsToRemove = features.getArray().map((f: any) => f.getId());

    idsToRemove.forEach((id: any) => {
      this._mapService.removeOverlay(id);
    });

    this._mapService.removeFeaturesById(this.drawLayer, idsToRemove);
    this.interactionSelect?.getFeatures().clear();
  }

  resetArea() {
    this.currentArea = 0;
  }

  resetLine() {
    this.currentLine = 0;
  }

  /**
   * First draw interaction
   */
  startDraw(id = AppConfig.IDBOD_USER_DRAWN): void {
    // Reset measurement for new layer
    this.currentArea = 0;
    this.currentLine = 0;

    this._verifyDrawLayerInitiated(id);

    // Reset to default values (for instance when using report after using draw)
    this.optionStrokeWidth = DrawHelper.STROKE_WIDTH;
    this.optionUseFillColor = true;
    this.optionShowMeasurement = true;
    this.changeDrawColor('rgba(255, 0, 0, 1)');
  }

  private _createDrawLayer(id = AppConfig.IDBOD_USER_DRAWN): void {
    // When we create a new drawLayer, we should also reset interactions, so they're not "stuck" on the old drawLayer
    this._removeDrawInteractions();

    const drawSource = new VectorSource({ wrapX: false });
    const drawVectorLayer = new VectorLayer({
      properties: { altitudeMode: 'clampToGround' },
      source: drawSource,
      // style: this.getDrawIconStyle(),
    });
    drawVectorLayer.set('id', id, true);
    drawVectorLayer.set('legend', 'no', true);
    drawVectorLayer.set('name', 'Tegnede objekter', true);

    if (id === AppConfig.IDBOD_USER_DRAWN) {
      this.overlaysToggle(true);
      // Add layer as custom TLSLayer to get it into activeLayers list
      this.drawLayer = this._themeLayersService.registerNewLayer(id, drawVectorLayer) as VectorLayer<VectorSource>;
    } else {
      // for area|forest|soil reports, add layer directly to map instead of via ThemeLayersService
      drawVectorLayer.setZIndex(AppConfig.ZINDEX_REPORT_DRAWINGS);
      this.drawLayer = drawVectorLayer;
      this.getMap().addLayer(drawVectorLayer);
    }
  }

  // Creates a new measure tooltip
  private _createMeasureTooltip(nr: number): string {
    const overlayId = this.drawLayer?.get('id') + nr.toString();
    let overlayMeasureElement = null;
    if (this._renderer) {
      overlayMeasureElement = this._renderer.createElement('div');
      overlayMeasureElement.className = 'tooltip tooltip-measure';
    }

    this.getMap().addOverlay(
      new Overlay({
        id: overlayId,
        element: overlayMeasureElement,
        offset: [0, -15],
        positioning: 'bottom-center',
        stopEvent: false,
        className: 'overlay-tooltip-container',
      })
    );
    return overlayId;
  }

  private _enableMapInteraction(interaction: Interaction): void {
    let exists = false;
    this.getMap()
      .getInteractions()
      .forEach(exInt => {
        if (exInt === interaction) {
          exists = true;
        }
      });
    if (!exists) {
      this.getMap().addInteraction(interaction);
    }
  }

  /**
   * When Escape keyboard button pressed, remove the last drawn point in current drawInteraction
   */
  private _eventListenerEscapeUndoPoint: EventListener = (evt: Event) => {
    if (evt instanceof KeyboardEvent && evt.key === 'Escape') {
      this.interactionDraw?.removeLastPoint();
    }
  };

  /**
   * Format area output.
   * @param {ol.geom.Polygon} polygon The polygon.
   * @return {string} Formatted area.
   */
  private _formatArea(polygon: Polygon): string {
    const area = polygon.getArea();
    this.currentArea = area;
    let output: string;
    if (area > 100000) {
      output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km\u00B2';
    } else {
      output = Math.round((area * 100) / 100) + ' ' + 'm\u00B2';
    }
    return output;
  }

  /**
   * Format length output.
   * @param {ol.geom.LineString} line The line.
   * @return {string} The formatted length.
   */
  private _formatLength(line: LineString): string {
    const length = line.getLength();
    this.currentLine = length;
    let output: string;
    if (length > 1000) {
      output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
    } else {
      output = Math.round((length * 100) / 100) + ' ' + 'm';
    }
    return output;
  }

  private _getDrawOptions(): DrawStyleOptions {
    return {
      chosenTool: this.chosenTool,
      color: this.optionColor,
      fillTransparency: DrawHelper.DEFAULT_FILL_TRANSPARENCY,
      iconSize: this.optionIconSize,
      iconSrc: this.optionIconSrc,
      inputString: this.optionInputString,
      strokeWidth: this.optionStrokeWidth,
      useFillColor: this.optionUseFillColor,
    };
  }

  /**
   * Callback for the layerChanges$ observable. Makes sure URL is in sync with layers
   */
  private _handleLayerChanges(layerChanges: LayerChange[]): void {
    if (!layerChanges?.length) {
      return;
    }

    // DrawService handles all LayerChanges for the user_drawn layer:
    const filtered = layerChanges.filter(lc => lc.layerid === AppConfig.IDBOD_USER_DRAWN);
    if (filtered.length) {
      const lc = filtered[0];
      // Make sure we have the drawLayer reference to work with
      this._verifyDrawLayerInitiated();

      // Handle adding or removing a layer
      if (lc.change.active !== undefined) {
        this.drawLayer?.setVisible(lc.change.active);
        this.overlaysToggle(lc.change.active);

        if (lc.change.active) {
          // When re-activating drawLayer, reset opacity of drawings and overlays
          // Emitting as global LayerChange so changes are reflected across app, such as Left:ActiveLayers
          this._themeLayersService.registerLayerChanges([
            { layerid: AppConfig.IDBOD_USER_DRAWN, change: { opacity: 1 } },
          ]);
        } else {
          // endDrawInteractions() will reset this.drawLayer so ordering is important here
          this.drawLayer?.setVisible(false);
          this.endDrawInteractions();
        }
      }

      if (lc.change.visible !== undefined) {
        this.overlaysToggle(lc.change.visible);
        this.drawLayer?.setVisible(lc.change.visible);
      }

      if (lc.change.opacity !== undefined) {
        this.overlaysSetOpacity(lc.change.opacity);
        this.drawLayer?.setOpacity(lc.change.opacity);
        this.drawLayer?.setVisible(true);
      }
    }
  }

  private _handleLayersReorder(layers: CatalogTreeItem[]): void {
    const drawLayer = this._themeLayersService.findActiveLayer(AppConfig.IDBOD_USER_DRAWN);
    if (drawLayer instanceof VectorLayer) {
      const drawnZIndex = drawLayer.getZIndex()!;
      // Overlays are placed in an absolute positioned parent div, so setting Z-indexes on each child will
      // have no effect relative to layers in the themeLayerGroup. Measure-tooltips will always bleed through and
      // show on top of other ThemeLayers. As a fallback, toggle these OpenLayers Overlay-tooltips and hide
      // them if the drawLayer is not the current topmost themelayer.
      this.overlaysToggle(drawnZIndex >= this._themeLayersService.getHighestExistingZIndex());
    }
  }

  private _isLinestringOrPolygon(feature: Feature): boolean {
    if (!feature) {
      return true;
    }
    const geo = feature.getGeometry();
    return !!geo && ['LineString', 'Polygon'].includes(geo.getType());
  }

  private _makeDrawInteraction(): Draw {
    let geoType = this.drawType as GeometryType;
    if (this.drawType === 'Text' || this.drawType === 'None') {
      geoType = 'Point' as GeometryType;
    }

    this.interactionDraw = new Draw({
      source: this.drawLayer?.getSource() || undefined,
      type: geoType,
      style: DrawHelper.getDrawStyle(true, this._getDrawOptions()),
    });
    let listener: any;
    let sketch: Feature;
    let drawNr: number;

    this.getMap()?.getTargetElement()?.classList.add('hide-cursor');

    this.interactionDraw.on('drawstart', (event: DrawEvent) => {
      this.hangingDrawing = true;
      drawNr = this.addDrawNr(this.optionShowMeasurement);
      const sd = DrawHelper.getDrawStyle(true, this._getDrawOptions());
      sketch = event.feature;
      sketch.setStyle(sd);
      sketch.setId(drawNr);
      sketch.set('type', this.chosenTool, true);

      if (this._isLinestringOrPolygon(sketch)) {
        const overlayId = this._createMeasureTooltip(drawNr);
        const overlay = this.getMap().getOverlayById(overlayId) as Overlay;
        this._updateMeasureTooltip(sketch);
        listener = sketch.getGeometry()?.on('change', (evt: BaseEvent) => {
          return this._updateMeasure(evt, overlay);
        });
      }

      document.addEventListener('keydown', this._eventListenerEscapeUndoPoint);

      this._onDrawStart$.next(event);
    });

    // Make sure we also remove the overlay if drawing is aborted
    this.interactionDraw.on('drawabort', (event: DrawEvent) => {
      this.hangingDrawing = false;
      sketch = event.feature;
      if (this._isLinestringOrPolygon(sketch)) {
        const overlayId = this.drawLayer?.get('id') + drawNr;
        const overlay = this.getMap().getOverlayById(overlayId);
        if (overlay) {
          this._mapService.removeOverlay(overlayId);
        }
      }

      document.removeEventListener('keydown', this._eventListenerEscapeUndoPoint);

      this._onDrawAbort$.next(event);
    });

    this.interactionDraw.on('drawend', (event: DrawEvent) => {
      this.hangingDrawing = false;
      const sketchId = drawNr.toString();
      const objClass = 'obj' + sketchId;
      const overlayId = this.drawLayer?.get('id') + sketchId;
      const overlay = this.getMap().getOverlayById(overlayId) as Overlay;

      if (this._isLinestringOrPolygon(event.feature)) {
        const classNames = 'tooltip tooltip-static';
        const overlayEl = overlay.getElement();
        if (overlayEl) {
          if (this.optionShowMeasurement) {
            overlayEl.className = classNames + ' ' + objClass;
          } else {
            overlayEl.className = classNames;
          }
        }
        overlay.setOffset([0, -7]);
        unByKey(listener);
      }

      if (this.chosenTool === 'LineString') {
        this._drawLine$.next({
          id: this.drawLayer?.get('id'),
          size: this.currentLine,
        });
      }

      if (this.chosenTool === 'Polygon') {
        this._drawArea$.next({
          id: this.drawLayer?.get('id'),
          size: this.currentArea,
        });
      }

      document.removeEventListener('keydown', this._eventListenerEscapeUndoPoint);

      this._onDrawEnd$.next(event);
    });

    this.getMap().addInteraction(this.interactionDraw);

    return this.interactionDraw;
  }

  private _makeModifyInteraction(drawId: string = AppConfig.IDBOD_USER_DRAWN): Modify {
    // console.log(`makeModifyInt`);

    // Make sure we have a drawLayer
    this._verifyDrawLayerInitiated(drawId);

    // Make sure drawLayer has a valid Source
    const src = (this.drawLayer as VectorLayer<VectorSource>).getSource();
    if (!src) {
      throw new Error('Cannot instantiate ModifyInteraction without VectorSource');
    }

    this.interactionModify = new Modify({
      source: src,
    });

    this.interactionModify.on('modifystart', (event: ModifyEvent) => {
      const sketch = event.features.getArray()[0];
      const sketchId = sketch.getId();
      this._drawing$.next({
        id: sketchId as number,
        layerId: this.drawLayer?.get('id'),
      });
      const overlay = this.getMap().getOverlayById(`${this.drawLayer?.get('id')}${sketchId}`);
      if (overlay) {
        const geo = sketch.getGeometry() as Geometry;
        geo.on('change', (evt: any) => this._updateMeasure(evt, overlay));
      }
    });

    this.interactionModify.on('modifyend', (event: ModifyEvent) => {
      this._drawLine$.next({
        id: this.drawLayer?.get('id'),
        size: this.currentLine,
      });
      this._drawArea$.next({
        id: this.drawLayer?.get('id'),
        size: this.currentArea,
      });
    });

    this.getMap().addInteraction(this.interactionModify);

    return this.interactionModify;
  }

  private _makeSelectInteraction(): Select {
    if (!this.drawLayer) {
      throw new Error('Cannot makeSelectInteraction without a drawLayer');
    }

    this.interactionSelect = new Select({
      condition: olClick,
      layers: [this.drawLayer],
      hitTolerance: 5,
    });

    // Stops feature.getStyle() from returning a function instead of the desired Style
    if (this.interactionSelect) {
      (this.interactionSelect as any).style_ = false;
    }

    this.interactionSelect.on('select', (e: SelectEvent) => {
      e.selected.forEach(feature => {
        const selectionStyle = DrawHelper.getSelectionStyle(
          feature,
          this.getMap().getView().getResolution() || 0,
          DrawHelper.CACHED_STYLE_KEY
        );
        feature.setStyle(selectionStyle);
      });

      e.deselected.forEach((feature: Feature) => {
        feature.setStyle(feature.get(DrawHelper.CACHED_STYLE_KEY));
      });

      this._onSelectionChange$.next({ selected: e.selected, deselected: e.deselected });
    });

    this.getMap().addInteraction(this.interactionSelect);

    return this.interactionSelect;
  }

  private _makeTranslateInteraction(): Translate {
    if (!this.drawLayer) {
      throw new Error('Cannot makeTranslateInteraction without a drawLayer');
    }

    this.interactionTranslate = new Translate({
      layers: [this.drawLayer],
      condition: shiftKeyOnly,
    });

    this.interactionTranslate.on('translatestart', (event: TranslateEvent) => {
      const sketch = event.features.getArray()[0];
      this._drawing$.next({
        id: sketch.getId() as number,
        layerId: this.drawLayer?.get('id'),
      });
    });

    this.interactionTranslate.on('translating', (event: TranslateEvent) => {
      const sketch = event.features.getArray()[0];
      const overlay = this.getMap().getOverlayById(`${this.drawLayer?.get('id')}${sketch.getId()}`);
      if (overlay) {
        overlay.setMap(this.voidMap);
      }
    });

    this.interactionTranslate.on('translateend', (event: TranslateEvent) => {
      const sketch = event.features.getArray()[0];
      const overlay = this.getMap().getOverlayById(`${this.drawLayer?.get('id')}${sketch.getId()}`);
      if (overlay) {
        const geo = sketch.getGeometry();
        if (geo instanceof Polygon) {
          overlay.setPosition(geo.getInteriorPoint().getCoordinates());
        } else if (geo instanceof LineString) {
          overlay.setPosition(geo?.getLastCoordinate());
        } else {
          overlay.setPosition(event.coordinate);
        }
        overlay.setMap(this.getMap());
      }

      this._drawLine$.next({
        id: this.drawLayer?.get('id'),
        size: this.currentLine,
      });
      this._drawArea$.next({
        id: this.drawLayer?.get('id'),
        size: this.currentArea,
      });
    });

    this.getMap().addInteraction(this.interactionTranslate);

    return this.interactionTranslate;
  }

  private _removeDrawInteractions(): void {
    this.getMap()
      .getInteractions()
      .forEach(int => {
        // Remove all interactions except the basic ones. Most of these are default OpenLayers Map interactions.
        // skogsbilveger_wfs uses Select for hover style effect.
        if (
          !(
            int instanceof DoubleClickZoom ||
            int instanceof DragAndDrop ||
            int instanceof DragPan ||
            int instanceof DragRotate ||
            int instanceof DragZoom ||
            int instanceof KeyboardPan ||
            int instanceof KeyboardZoom ||
            int instanceof MouseWheelZoom ||
            int instanceof PinchRotate ||
            int instanceof PinchZoom ||
            int instanceof Select
          )
        ) {
          this._removeInteraction(int);
        }
      });

    [this.interactionDraw, this.interactionModify, this.interactionSelect, this.interactionTranslate].forEach(int => {
      if (int instanceof Interaction) {
        this._removeInteraction(int);
      }
    });

    this.interactionDraw = undefined;
    this.interactionModify = undefined;
    this.interactionSelect = undefined;
    this.interactionTranslate = undefined;

    // this._debugToolService.logMapInteractionsToConsole('EOM removeDrawInteractions(), current interactions:');
  }

  private _removeInteraction(int: Interaction): void {
    if (int instanceof Draw) {
      this.getMap()?.getTargetElement()?.classList.remove('hide-cursor');
    }

    int?.setActive(false);
    int?.setMap(this.voidMap);
    this.getMap().removeInteraction(int);
  }

  private _updateDrawInteraction(from: string = '') {
    // DrawInteraction becomes outdated with each user selected option. Remove, recreate updated as needed.
    if (this.interactionDraw) {
      this._removeInteraction(this.interactionDraw);
      this.interactionDraw = undefined;
    }

    if (this.drawType === 'Modify') {
      this._updateSelectedFeaturesFromDrawOptions();
    }

    if (this.drawType !== 'Delete' && this.drawType !== 'Modify') {
      this._makeDrawInteraction();
    }

    // this._debugToolService.logMapInteractionsToConsole(from + '_updateDrawInteraction');
    // this._debugToolService.logMapLayersToConsole('_updateDrawInteraction');
  }

  private _updateMeasure(evt: BaseEvent, overlay: Overlay) {
    let tooltipCoord: Coordinate;
    const geo = evt.target;
    let output: string = '';

    if (geo instanceof Polygon) {
      output = this._formatArea(geo);
      tooltipCoord = geo.getInteriorPoint().getCoordinates();
      overlay.setPosition(tooltipCoord);
    } else if (geo instanceof LineString) {
      output = this._formatLength(geo);
      tooltipCoord = geo?.getLastCoordinate();
      overlay.setPosition(tooltipCoord);
    }
    overlay.getElement()!.innerHTML = output;
  }

  // Update a measure tooltip
  private _updateMeasureTooltip(feature: Feature) {
    let overlayId = '';
    if (feature.getId()) {
      overlayId = this.drawLayer?.get('id') + feature.getId()?.toString();
    }
    const overlay = this.getMap().getOverlayById(overlayId);
    if (this.optionShowMeasurement && overlay) {
      overlay?.getElement()?.parentElement?.removeAttribute('hidden');
    } else {
      overlay?.getElement()?.parentElement?.setAttribute('hidden', 'true');
    }
    feature.set('hasMeasurementOverlay', this.optionShowMeasurement);
  }

  private _updateSelectedFeaturesFromDrawOptions() {
    if (this.interactionSelect) {
      const selFeatures = this.interactionSelect.getFeatures();
      if (!selFeatures.getLength()) {
        return;
      }

      const options = this._getDrawOptions();
      options.fillTransparency = this.optionUseFillColor ? DrawHelper.DEFAULT_FILL_TRANSPARENCY : 0;

      selFeatures.forEach(feature => {
        options.chosenTool = feature.get('type');
        const featureId = feature.getId();
        const oldStyles: any = feature.getStyle();

        const newStyle = DrawHelper.getDrawStyle(false, options);

        if (this._isLinestringOrPolygon(feature) && featureId) {
          this._updateMeasureTooltip(feature);
        }

        if (oldStyles.length > 1) {
          const selectStyle = oldStyles[1].clone();
          if (newStyle.getStroke()) {
            const w = newStyle.getStroke()?.getWidth();
            if (w) {
              selectStyle.getStroke().setWidth(8 + w);
            }
          }

          if (options.chosenTool === 'Text') {
            const t = newStyle.getText()?.getText();
            const w = newStyle.getText()?.getStroke()?.getWidth();
            if (w) {
              selectStyle
                .getText()
                .getStroke()
                .setWidth(2 * w);
            }
            if (t) {
              selectStyle.getText().setText(t);
            }
          } else if (options.chosenTool === 'Point') {
            const circle = selectStyle.getImage();
            let scale = newStyle.getImage()?.getScale() || 1;
            if (scale instanceof Array) {
              scale = scale[0];
            }
            circle.setRadius(24 * scale);
          }

          // SelectionStyle below Feature but above ThemeLayers
          newStyle.setZIndex(AppConfig.ZINDEX_DRAW_ORIGIN);
          selectStyle.setZIndex(AppConfig.ZINDEX_DRAW_SELECT);

          feature.setStyle([newStyle, selectStyle]);
          feature.set(DrawHelper.CACHED_STYLE_KEY, newStyle.clone()); // make a copy
        } else {
          feature.setStyle(newStyle);
        }
      });
    }
  }

  private _verifyDrawLayerInitiated(drawId: string = AppConfig.IDBOD_USER_DRAWN): void {
    let existing: VectorLayer<VectorSource> | undefined = undefined;
    let zIndex: number = AppConfig.ZINDEX_REPORT_DRAWINGS;

    if (drawId === AppConfig.IDBOD_USER_DRAWN) {
      const found = this._themeLayersService.findActiveLayer(drawId);
      if (found instanceof VectorLayer) {
        existing = found;
        zIndex = AppConfig.ZINDEX_USER_DRAWN;
      }
    } else {
      existing = this._mapService.getLayer(drawId);
    }

    if (existing) {
      this.drawLayer = existing;
      this.drawLayer.setStyle(this.getDrawIconStyle());
      this.drawLayer.setVisible(true);
      this.drawLayer.setZIndex(zIndex);
    } else {
      this._createDrawLayer(drawId);
    }
  }
}

type LayerData = {
  id: string;
  size: number;
};

type DrawData = {
  id: number;
  layerId: string;
};
