import { Injectable } from '@angular/core';
import { PrintConfig } from '@kildenconfig/print-config';
import { MapService } from '@kildencore/services/map.service';
import { PrintTools } from '@kildencore/tools';
import { pointerMove } from 'ol/events/condition';
import { Extent } from 'ol/extent';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import Select from 'ol/interaction/Select';
import Translate from 'ol/interaction/Translate';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

@Injectable({
  providedIn: 'root',
})
export class MapPrintService {
  private printScale: number;
  private printLayout: string;
  private printPaper: string;
  private translate!: Translate;
  private printBox!: VectorLayer<VectorSource<Feature<Geometry>>>;
  private select!: Select;
  constructor(private mapService: MapService) {
    this.printScale = PrintConfig.DEFAULT_SCALE;
    this.printLayout = PrintConfig.PORTRAIT;
    this.printPaper = PrintConfig.A4;
  }

  public getPrintBox(): VectorLayer<VectorSource<Feature<Geometry>>> {
    if (!this.printBox) this.addPrintBox();
    return this.printBox;
  }

  /**
   * Add the print box to the map
   * @param  {any  = null}    center    [optional] custom center point of box
   * @return {boolean}                  whether or not the box was added to the map
   */
  public addPrintBox(center: any = null): boolean {
    const code = this.mapService.getCode();
    if (center === null) {
      this.printBox = PrintTools.createPrintLayer(
        this.createBoxFromCenter(PrintTools.getCenterOfExtent(this.mapService.getViewExtent()), this.printScale),
        code
      );
    } else {
      this.printBox = PrintTools.createPrintLayer(this.createBoxFromCenter(center, this.printScale), code);
    }

    this.select = new Select({
      condition: pointerMove,
      layers: [this.printBox],
      style: PrintTools.getStyle(PrintConfig.PRINT_BOX_COLOR_FILL_ACTIVE, PrintConfig.PRINT_BOX_COLOR_LINE, 6),
    });

    this.translate = new Translate({
      features: this.select.getFeatures(),
    });

    this.mapService.addInteraction(this.select);
    this.mapService.addInteraction(this.translate);

    return this.mapService.addLayer('printBox', this.printBox);
  }

  /**
   * Create a box layer from a center point and boxsize
   * @param  {any}        centerPoint   Center of box to be created
   *                                    this.mapService.getViewExtent()
   * @param  {number}     boxSize       Size of the box that should be created
   *                                    this.printScale
   * @return {Array<any>}               Coordinates (features) of the boundaries
   *                                    (corners) of the box
   */
  public createBoxFromCenter(centerPoint: any, boxSize: number) {
    const size = this.getSizeDefMapfish();
    const map = this.mapService.getMap();
    const s = boxSize;
    const view = map.getView();
    const center = map.getPixelFromCoordinate(centerPoint);
    const resolution = (view as any).getResolution();

    const w = ((((size.width / PrintConfig.DPI) * PrintConfig.MM_PER_INCHES) / 1000.0) * s) / resolution;
    const h = ((((size.height / PrintConfig.DPI) * PrintConfig.MM_PER_INCHES) / 1000.0) * s) / resolution;

    const minx = center[0] - w / 2;
    const miny = center[1] + PrintConfig.MOVE_DOWN - h / 2;
    const maxx = center[0] + w / 2;
    const maxy = center[1] + PrintConfig.MOVE_DOWN + h / 2;

    const UPPER_RIGHT = map.getCoordinateFromPixel([maxx, maxy]);
    const LOWER_RIGHT = map.getCoordinateFromPixel([maxx, miny]);
    const LOWER_LEFT = map.getCoordinateFromPixel([minx, miny]);
    const UPPER_LEFT = map.getCoordinateFromPixel([minx, maxy]);

    return [UPPER_RIGHT, LOWER_RIGHT, LOWER_LEFT, UPPER_LEFT];
  }
  /**
   * Remove the printBox from the map
   * @return {boolean}  whether or not the box was removed from the map
   */
  public removePrintBox(): boolean {
    this.mapService.removeInteraction(this.select);
    this.mapService.removeInteraction(this.translate);

    return this.mapService.removeLayer('printBox');
  }

  public hidePrintBox(): void {
    const pBox = this.mapService.getLayer('printBox');
    if (pBox) {
      pBox.setVisible(false);
    }
  }

  public showPrintBox(): void {
    if (this.isPrintBoxInViewExtent()) {
      const pBox = this.mapService.getLayer('printBox');
      pBox.setVisible(true);
    } else {
      this.removePrintBox();
      this.addPrintBox();
    }
  }

  /**
   * Check if the printbox is visible i the map.
   * return true if printbox is within view, false if printbox does not exist or is not inside view
   */
  public isPrintBoxInViewExtent() {
    const pBox = this.mapService.getLayer('printBox');
    if (pBox === null) {
      return false;
    }
    return this.mapService.isLayerInViewExtent(pBox);
  }
  /**
   * Set the print scale
   * @param {number} scale  new print scale
   */
  public setPrintScale(scale: number): void {
    this.printScale = scale;
  }

  /**
   * Set the print layout
   * @param {string} layout  new print layout
   */
  public setPrintLayout(layout: string): void {
    this.printLayout = layout;
  }

  /**
   * Set the print paper type
   * @param {string} paper  new paper type
   */
  public setPrintPaper(paper: string): void {
    this.printPaper = paper;
  }

  /**
   * Update/refresh the printBox
   * @param {boolean = false}     updateExistingPrintBox    [optional]
   *                              whether or not this is a new box,
   *                              or if we want to update an existing printBox
   */
  public updatePrintBox(updateExistingPrintBox: boolean = false): void {
    let center: Array<any>;
    this.removePrintBox();

    if (updateExistingPrintBox) {
      center = PrintTools.getCenterOfExtent(this.getPrintBoxExtent());
      this.addPrintBox(center);
    } else {
      this.addPrintBox();
    }
  }

  /**
   * Get the center of the printBox
   * @return {Array<any>}     center of the printBox
   */
  public getCenterOfBoxExtent(): Array<any> {
    return PrintTools.getCenterOfExtent(this.getPrintBoxExtent());
  }

  /**
   * Get the extent of the printBox
   * @return {Array<any>}     extent of the printBox
   */
  private getPrintBoxExtent(): Extent {
    const pbSrc = this.getPrintBox().getSource();
    if (!pbSrc) {
      throw 'Could not getSource() for printBox';
    }

    return pbSrc.getExtent();
  }

  private getSizeDefMapfish() {
    let size: any = {};
    const getSizeFromPanel = this.printPaper + '_' + this.printLayout;
    if (getSizeFromPanel === 'A4_PORTRAIT') {
      size = PrintConfig.SIZE_A4_PORTRAIT;
    } else if (getSizeFromPanel === 'A4_LANDSCAPE') {
      size = PrintConfig.SIZE_A4_LANDSCAPE;
    } else if (getSizeFromPanel === 'A3_PORTRAIT') {
      size = PrintConfig.SIZE_A3_PORTRAIT;
    } else if (getSizeFromPanel === 'A3_LANDSCAPE') {
      size = PrintConfig.SIZE_A3_LANDSCAPE;
    } else if (getSizeFromPanel === 'A0_PORTRAIT') {
      size = PrintConfig.SIZE_A0_PORTRAIT;
    } else if (getSizeFromPanel === 'A0_LANDSCAPE') {
      size = PrintConfig.SIZE_A0_LANDSCAPE;
    } else {
      size = PrintConfig.SIZE_A4_PORTRAIT;
    }
    return size;
  }
}
