<!-- Container, role banner is same as header-->
<div
  id="header"
  tabindex="-1"
  role="banner"
  class="custom_accordion"
  (dragenter)="preventDrop($event)"
  (dragover)="preventDrop($event)"
  (drop)="preventDrop($event)">
  <mat-accordion>
    <mat-expansion-panel [disabled]="true" [expanded]="panelOpenState" class="toolbar" hideToggle>
      <mat-expansion-panel-header [collapsedHeight]="collapsedHeight" [expandedHeight]="expandedHeight">
        <mat-panel-description>
          <a
            #nibioLogo
            (keyup.enter)="nibioLogo.click()"
            class="nibio-logo-wrapper"
            draggable="false"
            href="{{ logoUrl }}"
            tabindex="-1"
            target="_blank">
            <img
              [ngClass]="name === 'vega' ? 'logo' : 'nibio-logo'"
              draggable="false"
              tabindex="0"
              src="{{ logo }}"
              alt="NIBIO" />
          </a>
          <!--Kilden-->
          <h1 class="toolbar-text hidden-mobile">{{ title }}</h1>

          <!--Søkefelt-->
          <kilden3-search class="search"></kilden3-search>

          <!--Dropdown verktøy-->
          <button
            (keydown.enter)="$event.stopPropagation()"
            role="button"
            #menuTrigger="matMenuTrigger"
            [class.opened]="menuTrigger.menuOpen"
            class="toolbar-nav-tools no-margin-right navigation-style tools-btn tools-btn-left"
            [matMenuTriggerFor]="toolsmenu">
            <mat-icon class="toggle-panel">construction</mat-icon>
            <span>Verktøy</span>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <div class="moveRight">
            <img class="logo" *ngIf="name === 'vega' && logo2" src="{{ logo2 }}" />
            <img class="logo" *ngIf="name === 'vega' && logo3" src="{{ logo3 }}" />
            <img class="logo" *ngIf="name === 'vega' && logo4" src="{{ logo4 }}" />
            <img class="logo" *ngIf="name === 'vega' && logo5" src="{{ logo5 }}" />
          </div>

          <!-- Mat-menu for desktop and mobile -->
          <mat-menu class="custom_mat_menu" #toolsmenu="matMenu" hasBackdrop="false">
            <!-- Wildlife -->
            <button
              (click)="openWildlifeDialog()"
              *ngIf="shouldShowTool('viltkart')"
              [ngClass]="{ 'hidden-mobile': !shouldShowToolMobile('viltkart') }"
              mat-menu-item>
              <mat-icon class="black-icon">area_chart</mat-icon>
              Dyreportalen
            </button>

            <!-- Zones -->
            <mat-divider
              *ngIf="shouldShowTool('sonegrenser')"
              [ngClass]="{
                'hidden-mobile': !shouldShowToolMobile('sonegrenser'),
              }">
            </mat-divider>
            <button
              (click)="openGrantZonesDialog()"
              *ngIf="shouldShowTool('sonegrenser')"
              [ngClass]="{
                'hidden-mobile': !shouldShowToolMobile('sonegrenser'),
              }"
              mat-menu-item>
              <mat-icon class="black-icon">area_chart</mat-icon>
              Finn sone for distriktstilskudd
            </button>

            <!-- Draw -->
            <mat-divider
              *ngIf="shouldShowTool('draw') || shouldShowTool('measure')"
              [ngClass]="{
                'hidden-mobile': !shouldShowToolMobile('draw') && !shouldShowToolMobile('measure'),
              }">
            </mat-divider>
            <button
              *ngIf="shouldShowTool('draw') || shouldShowTool('measure')"
              (click)="openDrawDialog()"
              [disabled]="$is3dActive()"
              [ngClass]="{
                'hidden-mobile': !shouldShowToolMobile('draw') && !shouldShowToolMobile('measure'),
              }"
              [title]="$is3dActive() ? 'Ikke tilgjengelig i 3D-modus' : 'Tegning og måling i kartet'"
              mat-menu-item>
              <mat-icon class="black-icon">design_services</mat-icon>
              Tegne/måle
            </button>

            <!-- Compare -->
            <mat-divider
              *ngIf="shouldShowTool('compare')"
              [ngClass]="{ 'hidden-mobile': !shouldShowToolMobile('compare') }">
            </mat-divider>
            <button
              *ngIf="shouldShowTool('compare')"
              (click)="toggleLayerSwipe()"
              [disabled]="$is3dActive()"
              [ngClass]="{ 'hidden-mobile': !shouldShowToolMobile('compare') }"
              [title]="$is3dActive() ? 'Ikke tilgjengelig i 3D-modus' : 'Sammenlikn øverste kartlag med laget under'"
              mat-menu-item>
              <mat-icon class="black-icon">compare</mat-icon>
              Sammenligne kartlag
            </button>

            <!-- Upload -->
            <mat-divider
              *ngIf="shouldShowTool('addGPS')"
              [ngClass]="{ 'hidden-mobile': !shouldShowToolMobile('addGPS') }">
            </mat-divider>
            <button
              *ngIf="shouldShowTool('addGPS')"
              (click)="openUploadDialog()"
              [ngClass]="{ 'hidden-mobile': !shouldShowToolMobile('addGPS') }"
              title="Last opp geometridata fra fil"
              mat-menu-item>
              <mat-icon class="black-icon">library_add</mat-icon>
              Last opp fil
            </button>

            <!-- Report -->
            <ng-container>
              <mat-divider
                *ngIf="shouldShowTool('arealrapport')"
                [ngClass]="{
                  'hidden-mobile': !shouldShowToolMobile('arealrapport'),
                }">
              </mat-divider>
              <button
                *ngIf="shouldShowTool('arealrapport')"
                (click)="openAreaReportDialog()"
                [disabled]="$is3dActive()"
                [ngClass]="{
                  'hidden-mobile': !shouldShowToolMobile('arealrapport'),
                }"
                [title]="$is3dActive() ? 'Ikke tilgjengelig i 3D-modus' : 'Åpne rapportverktøy'"
                mat-menu-item>
                <mat-icon class="black-icon">description</mat-icon>
                Arealrapport
              </button>
              <mat-divider
                *ngIf="shouldShowTool('soilreport')"
                [ngClass]="{
                  'hidden-mobile': !shouldShowToolMobile('soilreport'),
                }">
              </mat-divider>
              <button
                *ngIf="shouldShowTool('soilreport')"
                (click)="openSoilReportDialog()"
                [disabled]="$is3dActive()"
                [ngClass]="{
                  'hidden-mobile': !shouldShowToolMobile('soilreport'),
                }"
                [title]="$is3dActive() ? 'Ikke tilgjengelig i 3D-modus' : 'Åpne rapportverktøy'"
                mat-menu-item>
                <mat-icon class="black-icon">description</mat-icon>
                Arealrapport jordsmonn
              </button>
              <mat-divider
                *ngIf="shouldShowTool('reportForest')"
                [ngClass]="{
                  'hidden-mobile': !shouldShowToolMobile('reportForest'),
                }">
              </mat-divider>
              <button
                *ngIf="shouldShowTool('reportForest')"
                (click)="openForestReportDialog()"
                [disabled]="$is3dActive()"
                [ngClass]="{
                  'hidden-mobile': !shouldShowToolMobile('reportForest'),
                }"
                [title]="$is3dActive() ? 'Ikke tilgjengelig i 3D-modus' : 'Åpne rapportverktøy'"
                mat-menu-item>
                <mat-icon class="black-icon">description</mat-icon>
                Skograpport
              </button>
            </ng-container>

            <!-- Kostra -->
            <mat-divider
              *ngIf="shouldShowTool('kostra')"
              [ngClass]="{ 'hidden-mobile': !shouldShowToolMobile('kostra') }">
            </mat-divider>
            <a href="https://kart9.nibio.no/kostra/" target="_blank">
              <button
                *ngIf="shouldShowTool('kostra')"
                [ngClass]="{ 'hidden-mobile': !shouldShowToolMobile('kostra') }"
                mat-menu-item>
                <mat-icon class="black-icon">assessment</mat-icon>
                Arealberegning Kostra
              </button>
            </a>

            <!-- Print -->
            <mat-divider *ngIf="currentTopicId !== 'viltkart' && !$is3dActive()" class="hidden-mobile"></mat-divider>
            <button
              *ngIf="currentTopicId !== 'viltkart' && !$is3dActive()"
              (click)="openPrintDialog()"
              class="hidden-mobile"
              mat-menu-item>
              <mat-icon class="black-icon">print</mat-icon>
              Skriv ut/lagre
            </button>

            <!-- 3D -->
            <ng-container *ngIf="shouldShowTool('3D')">
              <button (click)="toggle3d(!$is3dActive())" mat-menu-item>
                <mat-icon class="black-icon">3d_rotation</mat-icon>
                Slå {{ $is3dActive() ? 'av' : 'på' }} 3D-visning
              </button>
            </ng-container>
          </mat-menu>

          <!--Link, Gårdskart og informasjon-->
          <nav *ngIf="name !== 'vega'" class="toolbar-nav-right hidden-mobile">
            <ul>
              <li (keydown.enter)="$event.stopPropagation()" class="toolbar-nav-right-item">
                <a tabindex="-1">
                  <button class="toolbar-nav-tools navigation-style tools-btn" (click)="getPermalink()">
                    <mat-icon>link</mat-icon>
                    <span>Link</span>
                  </button>
                </a>
              </li>
              <li (keydown.enter)="$event.stopPropagation()" class="toolbar-nav-right-item">
                <button (click)="triggerFarmsMap()" class="toolbar-nav-tools navigation-style tools-btn">
                  <mat-icon>agriculture</mat-icon>
                  <span>Gårdskart</span>
                </button>
              </li>
              <li class="toolbar-nav-right-item">
                <kilden3-infolinks></kilden3-infolinks>
              </li>
            </ul>
          </nav>

          <div *ngIf="name !== 'vega'" class="toolbar-nav-right show-mobile">
            <button
              (keydown.enter)="$event.stopPropagation()"
              [class.opened]="panelOpenState"
              aria-label="Verktøymeny"
              class="toolbar-nav-tools no-margin-right navigation-style"
              multi
              (click)="toggleHamburgerPanel()">
              <mat-icon class="mat-icon-big">menu</mat-icon>
            </button>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <!--Hamburgermenu on mobil-->
      <span
        (click)="$event.stopPropagation()"
        (keydown.tab)="$event.stopPropagation()"
        (keydown.shift.tab)="$event.stopPropagation()"
        cdkTrapFocus
        class="mat-list-item-container">
        <button
          #matMenuTriggerMobile="matMenuTrigger"
          tabindex="0"
          class="mat-list-item-first"
          mat-menu-item
          [matMenuTriggerFor]="toolsmenu">
          <mat-icon class="black-icon">construction</mat-icon>
        </button>
        <div class="mat-list-item-right">
          <button mat-menu-item (click)="getPermalink()">
            <mat-icon class="black-icon">link</mat-icon>
          </button>
          <button mat-menu-item (click)="triggerFarmsMap()">
            <mat-icon class="black-icon">agriculture</mat-icon>
          </button>
          <!--button  mat-menu-item ><mat-icon class="black-icon" fontSet="material-icons-outlined">announcement</mat-icon></button-->
          <kilden3-infolinks></kilden3-infolinks>
        </div>
      </span>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<!-- prettier-ignore -->
<div id="farmMapPopover" class="farms-map-popover-content" [class.active]="isActive">
  Zoom inn til ønsket gård, trykk rød prikk for å vise info om driftssenteret
  og finne direktelenke til eiendommen i Gårdskart.
</div>
