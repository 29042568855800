import { AppConfig } from '@kildenconfig/app.config';
import { PrintConfig } from '@kildenconfig/print-config';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import { Layer } from 'ol/layer';
import OlLayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import OlSourceImageWMS from 'ol/source/ImageWMS';
import OlSourceTileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

export class PrintTools {
  /**
   * Calculate the shortest distance between two corners of a given extent
   *
   * @param  {any}    extent    extent to find shortest distance between corners
   *
   * @return {number}           shortest distance between two corners of extent
   */
  public static getSizeOfExtent(extent: any): number {
    return Math.min(
      Math.abs(Math.round(extent[PrintConfig.UPPER_X] - extent[PrintConfig.LOWER_X])),
      Math.abs(Math.round(extent[PrintConfig.UPPER_Y] - extent[PrintConfig.LOWER_Y]))
    );
  }

  /**
   * Create a printBox layer from the given coordinates
   *
   * @param  {any}             coordinates  Coordinates of the corners to the printBox
   * @param  {any}             code
   * @return {VectorLayer}              printBox layer based on the given input
   */
  public static createPrintLayer(coordinates: any, code: string) {
    coordinates.push(coordinates[0]); // fufill the polygonring
    const geojsonObject = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: code,
        },
      },
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [coordinates],
          },
        },
      ],
    };

    const propertySource = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject) as Feature<Geometry>[],
    });

    const printStyle = this.getStyle(PrintConfig.PRINT_BOX_COLOR_FILL, PrintConfig.PRINT_BOX_COLOR_LINE, 2);

    return new VectorLayer({
      properties: { altitudeMode: 'clampToGround' },
      source: propertySource,
      style: [printStyle],
      zIndex: Infinity,
    });
  }

  /**
   * Generate style for borderLayer
   * @param  {string}         fillColor     fill/area color
   * @param  {string}         strokeColor   stroke/border color
   * @param  {number}         strokeWidth   stroke/border width
   * @return {Style}               generated style for borderLayer
   */
  public static getStyle(fillColor: string, strokeColor: string, strokeWidth: number): Style {
    return new Style({
      stroke: new Stroke({
        color: strokeColor,
        width: strokeWidth,
      }),
      fill: new Fill({
        color: fillColor,
      }),
    });
  }

  /**
   * Calculate the center point of a given extent
   *
   * @param  {any}        extent  extent to calculate the center point of
   *
   * @return {Array<any>}         center point (coordinates) of given extent
   */
  public static getCenterOfExtent(extent: any): Array<any> {
    const X = extent[PrintConfig.LOWER_X] + (extent[PrintConfig.UPPER_X] - extent[PrintConfig.LOWER_X]) / 2;
    const Y = extent[PrintConfig.LOWER_Y] + (extent[PrintConfig.UPPER_Y] - extent[PrintConfig.LOWER_Y]) / 2;

    return [X, Y];
  }

  /**
   * Returns all layers from the given map.
   *
   * @param {OlMap|OlLayerGroup} collection The map or layergroup to get the
   *                                           layers from.
   * @return {Array} The layers.
   */
  static getMapLayers = (collection: Map | OlLayerGroup): Layer[] => {
    const mapLayers: Layer[] = [];

    collection.getLayers().forEach(layer => {
      if (layer instanceof OlLayerGroup) {
        if (layer.getVisible()) {
          PrintTools.getMapLayers(layer).forEach((l: any) => {
            mapLayers.push(l);
          });
        }
      } else {
        if (layer.getZIndex() === undefined) {
          // Make sure nothing is hidden behind backgroundlayers
          console.log('Dette layer må få en zIndex i koden for å få rett plassering:', layer.getProperties());
          if (layer.get('type') === 'base') {
            layer.setZIndex(AppConfig.ZINDEX_BACKGROUND);
          } else {
            layer.setZIndex(AppConfig.ZINDEX_THEMELAYERS);
          }
        }
        mapLayers.push(layer as Layer);
      }
    });

    return mapLayers;
  };

  /**
   * Generates the GetLegendGraphic url for the given layer.
   *
   * @param {ol.layer} layer The layer to generate the GetLegendGraphic for.
   * @return {string} The GetLegendGraphic url.
   */
  static getLegendGraphicUrl = (layer: Layer) => {
    if (layer.get('legendUrl')) {
      return layer.get('legendUrl');
    }

    //  console.log("layer.get('hasLegend')", layer.getSource().getProperties().config.hasLegend);
    const source = layer.getSource();

    if (source instanceof OlSourceTileWMS || source instanceof OlSourceImageWMS) {
      const customParams = layer.get('customPrintLegendParams');

      const { LAYERS, VERSION, FORMAT, ...passThroughParams } = source.getParams();
      const url =
        source instanceof OlSourceImageWMS
          ? source.getUrl()
          : // @ts-ignore: Object is possibly 'null'.
            source.getUrls()[0];
      const params = {
        LAYER: LAYERS.split(',')[0],
        VERSION: VERSION || '1.3.0',
        SERVICE: 'WMS',
        REQUEST: 'GetLegendGraphic',
        FORMAT: FORMAT || 'image/png',
        SLD_VERSION: '1.1.0',
        ...customParams,
        ...passThroughParams,
      };
      const queryParams = Object.keys(params)
        .map(key => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
        })
        .join('&');

      if (url!.endsWith('?')) {
        return `${url}${queryParams}`;
      } else {
        return `${url}?${queryParams}`;
      }
    }
  };

  public static isLayerInsideResoultion = (layer: any, map: any) => {
    if (layer.getSource() instanceof OlSourceImageWMS) {
      const resolution = map.getView().getResolution();
      const ltThanMin = resolution > layer?.getMinResolution();
      const gtThanMax = resolution < layer?.getMaxResolution();
      return ltThanMin && gtThanMax;
    } else return true;
  };

  /**
   * Format length output.
   * @param {ol.geom.LineString} line The line.
   * @return {string} The formatted length.
   */
  public static formatLength(line: LineString) {
    const length = line.getLength();
    let output;
    if (length > 1000) {
      output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
    } else {
      output = Math.round((length * 100) / 100) + ' ' + 'm';
    }
    return output;
  }

  /**
   * Format area output.
   * @param {ol.geom.Polygon} polygon The polygon.
   * @return {string} Formatted area.
   */
  public static formatArea(polygon: Polygon) {
    const area = polygon.getArea();
    let output;
    if (area > 100000) {
      output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km\u00B2';
    } else {
      output = Math.round((area * 100) / 100) + ' ' + 'm\u00B2';
    }
    return output;
  }
}
