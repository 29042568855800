import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AppConfig } from '@kildenconfig/app.config';
import { LayerHelper } from '@kildencore/helpers/layer.helper';
import { ZoomInfo } from '@kildencore/models';
import { LayersConfigService } from '@kildencore/services/data/layers-config.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapService } from '@kildencore/services/map.service';
import { PermaLinkService } from '@kildencore/services/perma-link.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { CatalogTreeItem } from '@kildenshared/components/catalog-tree/catalog-tree-item';
import { LayerChange } from '@kildenshared/interfaces';
import { CatalogLayerVariantInterface } from '@kildenshared/interfaces/catalog-layer-variant.interface';
import { CatalogLayerVariantsInterface } from '@kildenshared/interfaces/catalog-layer-variants.interface';
import ImageLayer from 'ol/layer/Image';
import VectorLayer from 'ol/layer/Vector';
import { ImageWMS } from 'ol/source';
import { concatMap, finalize, forkJoin, Observable, of, Subject, take, takeUntil, tap } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'kilden3-catalog-layer',
  templateUrl: './catalog-layer.component.html',
  styleUrls: ['./catalog-layer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogLayerComponent implements OnDestroy, OnInit {
  @Input()
  layer!: CatalogTreeItem;

  @Input()
  showExpandedView: boolean = false;

  canBeZoomed: boolean = false;
  isOutsideResolution: boolean = false;
  layerOpacity: number | undefined;
  mouseOn: boolean = false;
  orthoId = AppConfig.IDBOD_NIB_SATELLITE; // Used for debugging JIRA KILDEN3-543

  private readonly _onDestroy$ = new Subject<void>();

  private _idBod!: string;
  private _instanceCtx: string = 'none'; // Used for debugging JIRA KILDEN3-543
  // private _instanceId = 0; // // Used for debugging JIRA KILDEN3-543
  private _tlsLayer: ImageLayer<ImageWMS> | VectorLayer<any> | null | undefined;
  private _zoom: ZoomInfo = new ZoomInfo();
  private _objDiffer!: KeyValueDiffer<any, any>;

  get timeSeries(): number[] {
    if (!this.layer?.config?.timeEnabled || !this.layer?.timeOptions) {
      return [];
    }

    const timeOpts = this.layer.timeOptions;

    return Array.from(
      new Array((timeOpts.max ?? 0) - (timeOpts.min ?? 0) + 1),
      (val, idx) => +idx + +(timeOpts.min ?? 0) // Force read strings as numbers with +prefix
    );
  }

  // Used for debugging JIRA KILDEN3-543
  // get context(): string {
  //   this._instanceCtx = '?';
  //   const grandParent: HTMLElement = this._elementRef.nativeElement.parentElement;
  //   if (grandParent) {
  //     // console.log(grandParent, grandParent.parentElement);
  //
  //     if (grandParent.classList.contains('border-layer-level')) {
  //       this._instanceCtx = 'Kartlag';
  //     }
  //     if (grandParent.classList.contains('drag-item-wrapper')) {
  //       this._instanceCtx = 'AktiveKartlag';
  //     }
  //
  //     if (this._instanceCtx === '?') {
  //       console.groupCollapsed('context');
  //       console.log(`layer`, this.layer);
  //       console.log(`grandParent`, grandParent);
  //       console.log(`greatGrand`, grandParent.parentElement);
  //       console.log(`greatGreat`, grandParent.parentElement?.parentElement);
  //       console.groupEnd();
  //     }
  //   }
  //
  //   return this._instanceCtx;
  // }

  /**
   * Used by the loading indicator next to the variant select
   */
  set isFetchingVariants(isFetching: boolean) {
    if (this.layer.variants) {
      this.layer.variants.fetching = isFetching;
    } else {
      this.layer.variants = {
        fetching: isFetching,
        items: [],
      } as CatalogLayerVariantsInterface;
    }
    this._themeLayersService.registerLayerChanges([
      {
        layerid: this._idBod,
        change: {
          isFetchingVariants: isFetching,
        },
      },
    ]);
    this._cdr.detectChanges();
  }

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _elementRef: ElementRef, // Used for debugging JIRA KILDEN3-543
    private readonly _kildenStateService: KildenStateService,
    private readonly _kvDiffers: KeyValueDiffers,
    private readonly _layersConfigService: LayersConfigService,
    private readonly _mapService: MapService,
    private readonly _permaLinkService: PermaLinkService,
    private readonly _themeLayersService: ThemeLayersService
  ) {
    // Used for debugging JIRA KILDEN3-543
    // this._instanceId = Math.random();
    // console.log(`ctor`, this._instanceId, this.context);
    // setTimeout(() => {
    //   if (this.layer?.idBod === AppConfig.IDBOD_NIB_2) {
    //     console.log(`ctor flyfoto`, this._instanceId, this.context);
    //   }
    //   if (this.layer?.idBod === AppConfig.IDBOD_NIB_SATELLITE) {
    //     console.log(`ctor satellite`, this._instanceId, this.context);
    //   }
    // }, 2);
  }

  ngOnDestroy(): void {
    this._mapService.stopMapChanged();
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._cdr.detectChanges();
    // Used for debugging JIRA KILDEN3-543
    // if (this._idBod === this.orthoId) {
    //   console.log(`onDestroy ${this.orthoId}`, this._instanceId, this.context);
    // }
  }

  ngOnInit(): void {
    this._idBod = this.layer.idBod as string;

    this._objDiffer = this._kvDiffers.find(this.layer).create();

    if (AppConfig.ORTHO_EXCEPTIONS.includes(this._idBod)) {
      // console.log(`onInit ${this.orthoId}`, this._instanceId, this.context);
      if (this.layer.selectedOpen && this.showExpandedView) {
        this._initMapMoveObserve();
      }
    }

    this._determineLayerOpacity();

    if (LayerHelper.isUserProvided(this._idBod)) {
      this.canBeZoomed = true;
    }

    if (this.showExpandedView) {
      this._mapService.startMapChanged();
    }

    this._determineOutsideResolution();

    // Listen for zoom changes
    this._kildenStateService.zoomChanges$
      .pipe(
        tap(newZoom => {
          if (newZoom.hasResolution()) {
            this._zoom = newZoom;
            this._determineOutsideResolution();
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    // Merge in this layers config when it becomes available
    this._layersConfigService.layerConfigs$
      .pipe(
        concatMap(config => {
          const forks = [];
          const currentLayerCfg = config[this._idBod];
          if (!this._idBod || !currentLayerCfg) {
            return of(undefined);
          }

          this.layer.config = currentLayerCfg;
          this._tlsLayer = this._themeLayersService.findBankLayer(this._idBod);
          this._determineOutsideResolution();

          // Let opacity from /layersConfig take precedense
          if (this.layer.config?.opacity !== undefined) {
            this.layerOpacity = this.layer.config.opacity;

            this._themeLayersService.registerLayerChanges([
              {
                layerid: this.layer.idBod as string,
                change: {
                  opacity: this.layerOpacity,
                },
              },
            ]);
            this._cdr.detectChanges();
          }

          // Make sure there is an opacity value
          if (this.layerOpacity === undefined) {
            if (this.layer.opacity !== undefined) {
              this.layerOpacity = this.layer.opacity;
            } else if (this._tlsLayer) {
              this.layerOpacity = this._tlsLayer?.getOpacity();
            }
            if (this.layerOpacity === undefined) {
              this.layerOpacity = AppConfig.DEFAULT_LAYER_OPACITY;
            }

            this._permaLinkService.updateLayerParam(this.layer.idBod as string, 'opacity', this.layerOpacity);
            this._cdr.detectChanges();
          }

          if (AppConfig.ORTHO_EXCEPTIONS.includes(this._idBod)) {
            if (this.layer.selectedOpen && this.showExpandedView) {
              // console.log(`lc callback ${this.orthoId}`, this._instanceId, this.context);
              this.isFetchingVariants = true;
              forks.push(this._layersConfigService.getLayerVariants(this.layer));
            }
          }

          if (this.layer.config.timeEnabled) {
            forks.push(this._layersConfigService.getLayerTimeOptions(this.layer));
          }

          // Refactored to a forkJoin to allow both variants and timeoptions to be added and fetched.
          return forkJoin(forks).pipe(
            finalize(() => {
              this.isFetchingVariants = false;
              this._cdr.detectChanges();
            })
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._themeLayersService.layersChange$.pipe(takeUntil(this._onDestroy$)).subscribe({
      next: lcs => {
        const thisLayerChange = lcs.find(lc => lc.layerid === this._idBod);
        if (thisLayerChange) {
          if (thisLayerChange.change.active !== undefined) {
            // OnInit makes sure variants will be fetched on page load for active layers.
            // This case handles fetching variants when the layer is turned on after page loaded.
            if (
              AppConfig.ORTHO_EXCEPTIONS.includes(this._idBod) &&
              (thisLayerChange.change.active === true ||
                (thisLayerChange.change.visible === true && this.layer.selectedOpen))
            ) {
              if (!this.showExpandedView && !this.layer.variants?.items?.length) {
                this._fetchVariantsAndInitMoveObserve();
              }
            }
          }

          if (thisLayerChange.change.opacity !== undefined) {
            this._determineLayerOpacity(thisLayerChange.change.opacity);
          }

          if (this.layer.variants) {
            if (this.layer.variants.items?.length && !this.layer.variants.default) {
              this.layer.variants.default = this.layer.variants.items[0];
            }
            if (!this.showExpandedView && thisLayerChange.change.isFetchingVariants !== undefined) {
              this.layer.variants.fetching = thisLayerChange.change.isFetchingVariants;
            }
            if (thisLayerChange.change.selectedVariant !== undefined) {
              this.layer.variants.selected = thisLayerChange.change.selectedVariant;
            }
            if (this.layer.variants.default && !this.layer.variants.selected) {
              this.layer.variants.selected = this.layer.variants.default;
            }

            this._cdr.detectChanges();
          }
        }
      },
    });

    this._cdr.detectChanges();
  }

  /**
   * Set opacity directly so user immediately sees result
   */
  opacityChange(item: CatalogTreeItem, event: Event): void {
    if (!this._tlsLayer || !event.target) {
      return;
    }

    const slider: HTMLInputElement = event.target as HTMLInputElement;
    this._tlsLayer.setOpacity(slider.valueAsNumber);
    this._cdr.detectChanges();
  }

  /**
   * Report to service so other subscribers are notified
   */
  opacityChangeBroadcast(layer: CatalogTreeItem, event: Event): void {
    const slider: HTMLInputElement = event.target as HTMLInputElement;
    if (!slider) {
      return;
    }

    this._themeLayersService.registerLayerChanges([
      {
        layerid: layer.idBod as string,
        change: {
          opacity: slider.valueAsNumber,
        },
      },
    ]);
  }

  /**
   * User chose a new time (period) from the select
   */
  selectTimeOption(time: string): void {
    if (this.layer.timeOptions) {
      this.layer.timeOptions.selected = parseInt(time);
      this._themeLayersService.registerLayerChanges([
        {
          layerid: this.layer.idBod as string,
          change: {
            selectedTimeOption: this.layer.timeOptions.selected,
          },
        },
      ]);
      this._cdr.detectChanges();
    }
  }

  /**
   * User chose a new layer variant from the select
   */
  selectVariant(item: CatalogLayerVariantInterface): void {
    if (this.layer.variants) {
      this.layer.variants.selected = item || undefined;
      this._themeLayersService.registerLayerChanges([
        {
          layerid: this.layer.idBod as string,
          change: {
            selectedVariant: this.layer.variants.selected,
          },
        },
      ]);
      this._cdr.detectChanges();
    }
  }

  /**
   * Toggle given layer active or not
   */
  toggleLayerItem(item: CatalogTreeItem, enableLayer?: boolean): void {
    if (enableLayer !== undefined) {
      item.selectedOpen = enableLayer;
    } else {
      item.selectedOpen = item.selectedOpen === undefined ? false : !item.selectedOpen;
    }

    // Notify service of the change in layers
    if (item.idBod) {
      const lc: LayerChange = {
        layerid: item.idBod,
        change: {
          active: item.selectedOpen,
        },
      };

      // Add timeOptions if applicable
      if (item.config?.timeEnabled && item.timeOptions !== undefined) {
        lc.change.selectedTimeOption = !item.selectedOpen
          ? item.timeOptions?.default
          : (item.timeOptions?.selected ?? item.timeOptions?.default);
      }

      this._themeLayersService.registerLayerChanges([lc]);
    }

    this._cdr.detectChanges();
  }

  toggleLayerVisibility(): void {
    this.layer.visible = !this.layer.visible;

    this._themeLayersService.registerLayerChanges([
      {
        layerid: this.layer.idBod,
        change: {
          visible: this.layer.visible,
        },
      } as LayerChange,
    ]);

    this._cdr.detectChanges();
  }

  zoomToLayerExtent(): void {
    const themeLayer = this._themeLayersService.findActiveLayer(this._idBod);
    if (themeLayer) {
      this._mapService.zoomToLayer(themeLayer);
      this._cdr.detectChanges();
    }
  }

  private _determineOutsideResolution(): void {
    this.isOutsideResolution =
      (this.layer.minResolution !== undefined && this._zoom.resolution < this.layer.minResolution) ||
      (this.layer.maxResolution !== undefined && this._zoom.resolution > this.layer.maxResolution);
    this._cdr.detectChanges();
  }

  private _initMapMoveObserve(): void {
    if (!this.showExpandedView) {
      return;
    }
    // console.log(`initMapMoveObserve`, this._idBod, this._instanceId, this.context);

    this._mapService.mapMove$
      .pipe(
        debounceTime(1500), // Wait 1.5s before launching, user might move several times consequtively
        switchMap(evt => {
          if (!this.layer.selectedOpen) {
            return of(undefined);
          }

          // console.log('mapMove', this._idBod, this._instanceId, this.context);
          return this._updateVariants().pipe(
            tap({
              finalize: () => {
                this.isFetchingVariants = false;
                this._cdr.detectChanges();
              },
            }),
            take(1)
          );
        }),
        // retry({ count: 3, delay: 250 }),
        finalize(() => {
          this.isFetchingVariants = false;
          this._cdr.detectChanges();
        }),
        takeUntil(this._onDestroy$),
        catchError((e: Error) => {
          // console.warn(`mapMove$ catchError`, e);
          return this._updateVariants(false);
          // return throwError(() => e);
        })
      )
      .subscribe();
  }

  private _updateVariants(clearExisting: boolean = true): Observable<CatalogLayerVariantsInterface | undefined> {
    // Avoid duplicate listeners from both the instance of this comp in "Kartlag" and "Aktive kartlag"
    // Used for debugging JIRA KILDEN3-543
    // if (this._idBod === this.orthoId) {
    //   console.log(`updateVariants ${this.orthoId}`, this._instanceId, this.showExpandedView, this.context);
    // }
    if (!this.showExpandedView) {
      // console.log(`${this._idBod} collapsed, early return`);
      return of(undefined);
    }

    this.isFetchingVariants = true;

    if (clearExisting) {
      // Clear existing variants
      if (this.layer.variants) {
        this.layer.variants.items = undefined;
      }
      this._layersConfigService.clearCachedVariants(this._idBod);
    }

    // Fetch new variants for the new extents after mapmove
    return this._layersConfigService.getLayerVariants(this.layer);
  }

  private _determineLayerOpacity(newOpacityVal?: number): void {
    if (newOpacityVal !== undefined) {
      this.layerOpacity = newOpacityVal;
      return;
    }

    if (this.layer.opacity !== undefined) {
      this.layerOpacity = this.layer.opacity;
    } else {
      if (!this._tlsLayer) {
        this._tlsLayer = this._themeLayersService.findBankLayer(this._idBod);
      }
      if (this._tlsLayer) {
        this.layerOpacity = this._tlsLayer.getOpacity();
      } else {
        this.layerOpacity = AppConfig.DEFAULT_LAYER_OPACITY;
      }
    }
    this._cdr.detectChanges();
  }

  private _fetchVariantsAndInitMoveObserve(): void {
    this.isFetchingVariants = true;
    this._layersConfigService.getLayerVariants(this.layer).pipe(take(1)).subscribe();
    this._initMapMoveObserve();
  }
}
