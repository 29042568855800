<div
  id="leftPanel"
  class="leftPanel"
  draggable="false"
  role="main"
  (dragenter)="preventDrop($event)"
  (dragover)="preventDrop($event)"
  (drop)="preventDrop($event)">
  <mat-sidenav-container class="sidenav-container" draggable="false">
    <mat-sidenav
      #sidenav
      [opened]="!isMobileScreen"
      (opened)="kildenStateService.changeSidenavState(true)"
      (closed)="kildenStateService.changeSidenavState(false)"
      (openedStart)="toggleSidenav(true)"
      (closedStart)="toggleSidenav(false)"
      class="kilden-sidenav"
      draggable="false"
      mode="side">
      <div class="outer" draggable="false">
        <ng-container>
          <kilden3-layer-search *ngIf="haveLayerSearch"></kilden3-layer-search>
        </ng-container>
        <kilden3-topic-panel></kilden3-topic-panel>
        <div class="inner_remaining layer_tabs" draggable="false">
          <mat-tab-group draggable="false" [(selectedIndex)]="layerTabIndex">
            <mat-tab draggable="false" label="Kartlag" active>
              <kilden3-catalog-tree draggable="false"></kilden3-catalog-tree>
            </mat-tab>

            <mat-tab class="active-layers" draggable="false">
              <ng-template mat-tab-label>
                Aktive kartlag
                <span *ngIf="activeLayers.length > 0" class="counter">({{ activeLayers.length }})</span>
              </ng-template>
              <div (cdkDropListDropped)="activeLayerCdkDropped($event)" cdkDropList class="drag-list drag-vertical">
                <div
                  *ngFor="let item of activeLayers; let idx = index"
                  cdkDrag
                  cdkDragLockAxis="y"
                  class="drag-list-item">
                  <div *cdkDragPlaceholder class="drag-item-placeholder"></div>

                  <div class="drag-item-wrapper drag-item-{{ item.idBod }}">
                    <div
                      #dragHandleRef
                      (keyup.arrowUp)="activeLayerOrderUp(item, dragHandleRef)"
                      (keyup.arrowDown)="activeLayerOrderDown(item)"
                      [title]="dragHandleTitle(dragHandleRef)"
                      class="drag-item-handle"
                      cdkDragHandle
                      tabindex="0">
                      <sup>↑</sup><sub>↓</sub>
                    </div>

                    <kilden3-catalog-layer [layer]="item" [showExpandedView]="true"></kilden3-catalog-layer>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content> </mat-sidenav-content>
  </mat-sidenav-container>

  <button
    (click)="sidenav.toggle()"
    [ngClass]="{ slideIn: openLeftPanel, slideOut: !openLeftPanel }"
    class="inOutButton"
    draggable="false"
    id="sidenavToggle">
    <mat-icon
      aria-hidden="false"
      aria-label="Expand icon"
      draggable="false"
      fontSet="material-icons-outlined"
      [ngClass]="{
        leftPanelIconOut: openLeftPanel,
        leftPanelIconIn: !openLeftPanel,
      }">
      file_upload
    </mat-icon>
  </button>

  <kilden3-legend #legendRef draggable="false"></kilden3-legend>

  <label
    hidden
    id="showLandsbrukseiendomText"
    [ngClass]="openLeftPanel ? 'openLeftPanelClass' : 'closedLeftPanelClass'"></label>
</div>
