import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@kildenconfig/app.config';
import { LayerHelper } from '@kildencore/helpers/layer.helper';
import { SearchService } from '@kildencore/services/data/search.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapService } from '@kildencore/services/map.service';
import { allowedQueryParams, PermaLinkService } from '@kildencore/services/perma-link.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { moveCursorToStart } from '@kildencore/tools';
import { DeviceTypesEnum } from '@kildenshared/constants/device-types.enum';
import { TopicIdsEnum } from '@kildenshared/constants/topic-ids.enum';
import { SearchDataItem } from '@kildenshared/types/search/search-data-item.type';
import { SearchMergedData } from '@kildenshared/types/search/search-merged-data.type';
import { SearchResponseData } from '@kildenshared/types/search/search-response-data.type';
import { SearchValue } from '@kildenshared/types/search/search-value.type';
import { Coordinate } from 'ol/coordinate';
import { containsCoordinate, getBottomLeft, getBottomRight, getTopLeft, getTopRight } from 'ol/extent';
import Feature from 'ol/Feature';
import OlFormatGeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import BaseLayer from 'ol/layer/Base';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import Overlay from 'ol/Overlay';
import { fromLonLat, transform, transformExtent } from 'ol/proj';
import Projection from 'ol/proj/Projection';
import VectorSource from 'ol/source/Vector';
import OlStyleFill from 'ol/style/Fill';
import OlStyleIcon from 'ol/style/Icon';
import OlStyleImage from 'ol/style/Image';
import OlStyleStroke from 'ol/style/Stroke';
import OlStyleStyle from 'ol/style/Style';
import { concatMap, filter, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'kilden3-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
  readonly COORDINATE_LAYER_ID = 'search-coordinate';
  readonly COORDINATE_POINT_ID = 'search-coordinate-point';
  readonly DEFAULT_SEARCH_PLACEHOLDER =
    'Søk fylke, kommune, sted, adresse, gårds- og bruksnummer, koordinater (nord,øst)';

  currentTitle = this.DEFAULT_SEARCH_PLACEHOLDER;
  isLandbrukseiendom: boolean = false;
  processSearchSubject$ = new Subject<SearchValue>();
  searchTermCtrl!: FormControl<string | null>;
  stateForm = this._formBuilder.group({
    searchTerm: '',
  });
  stateGroupOptions: Observable<SearchMergedData[] | null> = of(null);

  private readonly _onDestroy$ = new Subject<void>();

  private _deviceType!: DeviceTypesEnum;
  private propertyList: SearchDataItem[] = [];

  private stateGroupOptionsData = this.processSearchSubject$.pipe(
    debounceTime(1000),
    takeUntil(this._onDestroy$),
    switchMap((value: SearchValue) => {
      // Clear any existing coordinate layer features (from previous searches)
      const existing = this._themeLayersService.findBankLayer(this.COORDINATE_LAYER_ID);
      if (existing) {
        LayerHelper.removeVectorFeatures((existing as VectorLayer<any>).getSource());
      }

      // When we dynamically trigger oninput, searchTerm is not a string anymore
      const sif = document.getElementById('search-input-field') as HTMLInputElement;
      if (typeof value.searchTerm === 'string' || (value.searchTerm as any) instanceof String)
        sif.dataset['searchTerm'] = value.searchTerm as string;
      else value.searchTerm = sif.dataset['searchTerm'];

      // Special handling if deemed to be a valid coordinate:
      // Jump directly to coordinate without showing search results list
      const coordinate = this._parseAsCoordinate(value.searchTerm);
      if (coordinate?.valid && coordinate?.xy) {
        return this._jumpToCoordinate(coordinate);
      }

      // else fetch current topic and continue with usual search + results list
      return this._kildenStateService.topic$.pipe(
        concatMap((topicValue: TopicIdsEnum | string) => {
          return this._searchService.getAll(value.searchTerm, this._mapService.getCode(), topicValue);
        })
      );
    })
  );

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _formBuilder: FormBuilder,
    private readonly _kildenStateService: KildenStateService,
    private readonly _mapService: MapService,
    private readonly _permaLinkService: PermaLinkService,
    private readonly _searchService: SearchService,
    private readonly _themeLayersService: ThemeLayersService
  ) {}

  ngOnInit(): void {
    this.searchTermCtrl = this.stateForm.controls.searchTerm;
    this._kildenStateService.deviceType$
      .pipe(
        tap(type => {
          this._deviceType = type;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._initSearch();
    this._initMunicipalityCountyLookup();
  }

  private latLon3857(lat: number, lon: number): Array<number> {
    const p = this._mapService.getCode();
    const ll = p === 'EPSG:3857' ? transform([lon, lat], 'EPSG:25833', p) : [lon, lat];
    return ll.map(l => (Math.round(l * 100) / 100) as number);
  }

  private processLocationData(data: SearchResponseData): SearchMergedData[] {
    const { location, fylkeKommune, property, reindeer } = data;

    let mergedData: SearchMergedData[] = [];

    if (fylkeKommune && fylkeKommune.length && typeof fylkeKommune !== 'string') {
      const fylkeKommuneItems = [
        {
          category: SearchCategoriesEnum.COUNTY,
          data: fylkeKommune.map((d: any) => ({
            catType: 'countyMunicipality',
            title: d.attrs.title,
            id: d.id,
            type: d.attrs.objektType,
          })),
        },
      ];
      mergedData = [...mergedData, ...fylkeKommuneItems];
    }

    if (location && typeof location !== 'string' && location.navn.length) {
      const navnetypeFilter = ['Adressenavn', 'Kommune', 'Fylke'];

      const locations = [
        {
          category: SearchCategoriesEnum.PLACE,
          data: location.navn
            .filter(
              (n: any) =>
                (n.stedstatus.toLowerCase() === 'aktiv' || n.stedstatus.toLowerCase() === 'vedtatt') &&
                navnetypeFilter.indexOf(n.navneobjekttype) === -1
            )
            .map((d: any) => ({
              catType: 'location',
              title: `${d.stedsnavn[0]?.skrivemåte}, ${d.navneobjekttype}, ${
                d.kommuner ? d.kommuner[0]?.kommunenavn : ''
              }`,
              x: (Math.round(d.representasjonspunkt.nord * 100) / 100) as number,
              y: (Math.round(d.representasjonspunkt.øst * 100) / 100) as number,
            })),
        },
      ];
      mergedData = [...mergedData, ...locations];
    }

    if (property && property.length && typeof property !== 'string') {
      const properties = [
        {
          category: SearchCategoriesEnum.ADDRESS,
          data: property
            .filter((e: any) => !(e.attrs.objektType === 'VEG' && !e.attrs.lat && !e.attrs.lon))
            .map((p: any) => ({
              catType: 'property',
              title: p.attrs.title.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (l: string) => l.toUpperCase()),
              municipalityNumb: p.attrs?.municipalityNumb || '0',
              gnr: p.attrs?.gnr || 0,
              bnr: p.attrs?.bnr || 0,
              fnr: p.attrs?.fnr || 0,
              x: this.latLon3857(p.attrs.lat, p.attrs.lon)[1],
              y: this.latLon3857(p.attrs.lat, p.attrs.lon)[0],
              origin: p.attrs?.origin.toLowerCase() || '',
              objektType: p.attrs?.objektType.toLowerCase() || '',
            })),
        },
      ];
      mergedData = [...mergedData, ...properties];
    }

    if (reindeer && reindeer.length && typeof reindeer !== 'string') {
      const reindeers = [
        {
          category: SearchCategoriesEnum.DEER_DISTRICT,
          data: reindeer.map((r: any) => ({
            catType: 'reindeer',
            title: r.attrs.title,
            kode: r.kode,
          })),
        },
      ];
      mergedData = [...mergedData, ...reindeers];
    }

    return mergedData;
  }

  getProperties(data: SearchDataItem) {
    data.checkProperties = true;
  }

  displayFn(option: SearchDataItem | string): string {
    if (typeof option === 'string') {
      return option;
    }

    return option && option.title ? option.title : '';
  }

  onChange(selectedData: SearchDataItem) {
    moveCursorToStart('search-input-field');
    this.currentTitle = selectedData.title;
    this.setTextInKildenHeader('');

    if (selectedData.catType === 'location') {
      this.showLocation(selectedData);
    }

    if (selectedData.catType === 'countyMunicipality') {
      if (selectedData.id && selectedData.type) {
        this._searchService
          .getMunicipalityCountyBorder(this._mapService.getCode(), selectedData.id, selectedData.type)
          .pipe(
            take(1),
            tap(data => {
              this.showFeaturesInMap(data);
              this._permaLinkService.setParamReplaceHistory(allowedQueryParams.KOMNR, selectedData.id as string);
            })
          )
          .subscribe();
      }
    }

    if (selectedData.catType === 'property') {
      if (
        selectedData.origin === 'matrikkel' &&
        (selectedData.objektType === 'eiendom' || selectedData.objektType === 'matrikkeladresse')
      ) {
        if (selectedData.municipalityNumb && selectedData.gnr && selectedData.bnr) {
          if (selectedData.checkProperties) {
            // Fix KILDEN3-424: Reset value, so it's not already determined for the users potential next result click:
            selectedData.checkProperties = undefined;

            this.propertyList = [];
            this.isLandbrukseiendom = false;
            this._searchService
              .getProperties(selectedData.municipalityNumb, selectedData.gnr, selectedData.bnr, selectedData.fnr || 0)
              .subscribe(data => {
                if (data.length) {
                  this.isLandbrukseiendom = true;
                  data.forEach((property: any) => {
                    property.municipalityNumb = property.knr;
                    this.getData(property, this.isLandbrukseiendom, data.length);
                  });
                } else this.getData(selectedData, this.isLandbrukseiendom, 0);
              });
          } else this.getData(selectedData, this.isLandbrukseiendom, 0);
        } else {
          // prettier-ignore
          if (!selectedData.y || !selectedData.x) Swal.fire({
            html: 'Eiendommen kan ikke stedfestes',
            icon: 'info',
          });
          else this.showLocation(selectedData);
        }
      } else this.showLocation(selectedData);
    }

    if (selectedData.catType === 'reindeer') {
      if (selectedData.kode !== undefined) {
        this._searchService
          .getReindeerDistrict(selectedData.kode, this._mapService.getCode())
          .subscribe(data => this.showFeaturesInMap(data));
      }
    }
  }

  private setTextInKildenHeader(text: string) {
    let landbrukseiendomElement: any = null;
    landbrukseiendomElement = document.getElementById('showLandsbrukseiendomText');
    landbrukseiendomElement.innerText = text;
    text ? landbrukseiendomElement.removeAttribute('hidden') : landbrukseiendomElement.setAttribute('hidden', 'hidden');
  }

  private getData(selectedData: SearchDataItem, isLandbrukseiendom: Boolean = false, numberOfProperties: 0) {
    this._searchService
      .getProperty(
        selectedData.municipalityNumb as string,
        selectedData.gnr as number,
        selectedData.bnr as number,
        selectedData.fnr as number,
        this._mapService.getCode()
      )
      .subscribe(data => {
        if (isLandbrukseiendom) {
          this.propertyList.push(data);
        }
        if (numberOfProperties === this.propertyList.length && isLandbrukseiendom) {
          this.showFeaturesInMap(this.propertyList, true);
          this.setTextInKildenHeader('Landbrukseiendom');
        } else {
          this.showFeaturesInMap(data, false);
          this.setTextInKildenHeader('Grunneiendom');
        }
      });
  }

  private showFeaturesInMap(data: any, isLandbrukseiendom: boolean = false, zoomMap = true) {
    const ms = this._mapService;
    // console.log('showFeaturesInMap', ms.getCode(), ms.projOn3dInit);
    const map = ms.getMap();
    const newLayer = this.createJsonLayer();
    this.removeSearchFromMap();
    let mainSource: VectorSource | null = null;
    map.addLayer(newLayer);

    if (data?.features && data?.features.length > 0) {
      const source = newLayer.getSource();
      const readOptions: { [key: string]: any } = {};
      const fileProjection = new OlFormatGeoJSON().readProjection(data);
      if (fileProjection) {
        // console.log('data projection from', fileProjection.getCode());
        // readOptions['dataProjection'] = fileProjection.getCode(); // Usually EPSG:4326
      }

      const features = new OlFormatGeoJSON().readFeatures(data, readOptions) as Feature<Geometry>[];
      source?.addFeatures(features);
      mainSource = source;
    }

    if (isLandbrukseiendom && data.length > 0) {
      const source = newLayer.getSource();
      data.forEach((property: any, index: number) => {
        if (property?.features && property?.features.length > 0) {
          const features = new OlFormatGeoJSON().readFeatures(property) as Feature<Geometry>[];
          source?.addFeatures(features);
        }
      });
      mainSource = source;
    }

    if (mainSource && zoomMap) {
      const padAmount =
        this._deviceType === DeviceTypesEnum.DESKTOP ? AppConfig.ZOOM_PADDING_DESKTOP : AppConfig.ZOOM_PADDING_MOBILE;

      // Zoom to fit results, with padding
      map.getView().fit(mainSource?.getExtent(), { padding: padAmount });
      // Fix: change proj --> 3D --> search kommunegrense
      if (ms.$is3dActive())
        mainSource.getFeatures().forEach(f => f.getGeometry()?.transform(ms.getCode(), ms.projOn3dInit));
    }
  }

  clearSearch() {
    // Overwrite to clear results dropdown immediately (otherwise would wait for debounce+callbacks)
    this.stateGroupOptions = of(null);
    this.stateForm.get('searchTerm')?.setValue('');
    this._initSearch();
    this.currentTitle = this.DEFAULT_SEARCH_PLACEHOLDER;
    this.setTextInKildenHeader('');
    this.removeSearchFromMap();
    moveCursorToStart('search-input-field'); // Used here to set focus in input
    this._searchService.clearSearch(); // Notify others such as permaLinkService
  }

  customStopPropagation(e: KeyboardEvent) {
    if (e.key === ' ') {
      e.stopPropagation();
    }
  }

  private removeSearchFromMap() {
    const map = this._mapService.getMap();
    // const marker = map.getOverlayById('searchInfomarker');
    const marker = this._mapService.getLayer('searchInfomarker');

    // if (marker) map.removeOverlay(marker);
    if (marker) map.removeLayer(marker);

    const coordinateLayer = this._themeLayersService.findBankLayer(this.COORDINATE_LAYER_ID);
    if (coordinateLayer?.getVisible()) {
      this._themeLayersService.registerLayerChanges([
        {
          layerid: this.COORDINATE_LAYER_ID,
          change: { active: false },
        },
      ]);
    }

    map
      .getLayers()
      .getArray()
      .slice()
      .forEach(layer => {
        if (layer instanceof LayerGroup) {
          layer
            .getLayers()
            .getArray()
            .slice()
            .forEach(subLayer => {
              this._checkRemovalLayer(subLayer);
            });
        } else {
          this._checkRemovalLayer(layer);
        }
      });
  }

  private createJsonLayer() {
    const styles = [
      new OlStyleStyle({
        stroke: new OlStyleStroke({
          color: 'rgba(255, 80, 80, 1)',
          width: 3,
        }),
        fill: new OlStyleFill({
          color: 'rgba(255, 100, 50, 0)',
        }),
      }),
    ];

    const vectorLayer = new VectorLayer({
      properties: { altitudeMode: 'clampToGround' },
      zIndex: AppConfig.ZINDEX_SEARCH,
      source: new VectorSource<Feature<Geometry>>({ features: [] }),
      style: () => styles,
    });
    vectorLayer.set('id', BORDER_ID, true);

    return vectorLayer;
  }

  private showLocation(selectedLocation: SearchDataItem) {
    const ms = this._mapService;
    // console.log('showLocation', ms.getCode(), ms.projOn3dInit);
    this.removeSearchFromMap();
    if (!selectedLocation.y || !selectedLocation.x) return;

    const locationMarkerLayer = new Overlay({
      id: 'searchInfomarker',
      position: [selectedLocation.y, selectedLocation.x],
      positioning: 'bottom-center',
      className: 'location-icon',
    });

    // ms.getMap().addOverlay(locationMarkerLayer);

    // Use layer instead of overlay to:
    //   - avoid error "parent_ is null" in console
    //   - make it work in 3D
    const pFeature = new Feature({
      geometry: new Point([selectedLocation.y, selectedLocation.x]),
    });
    // prettier-ignore
    pFeature.setStyle(new OlStyleStyle({
      image: new OlStyleIcon({
        anchor: [0.5, 1],
        src: './assets/location/kldn_pin_green.svg'
      }),
    }));
    const pLayer = new VectorLayer({
      properties: { id: 'searchInfomarker', altitudeMode: 'clampToGround' },
      source: new VectorSource({ features: [pFeature] }),
      zIndex: AppConfig.ZINDEX_SEARCH,
    });
    ms.getMap().addLayer(pLayer);
    const le = pLayer.getSource()?.getExtent()!;
    // prettier-ignore
    ms.getMap().getView().fit(le, { maxZoom: 10, padding: [50, 50, 50, 50] });
    // Fix: change proj --> 3D --> search sted
    if (ms.$is3dActive()) pFeature.getGeometry()?.transform(ms.getCode(), ms.projOn3dInit);
  }

  private _checkRemovalLayer(layer: BaseLayer): void {
    const map = this._mapService.getMap();
    if (layer?.get('id') === BORDER_ID) {
      map.removeLayer(layer);
    }
  }

  private _initMunicipalityCountyLookup(): void {
    // If komnr specified in url:
    const municipalityCountyNr = this._permaLinkService.getInitialValue(allowedQueryParams.KOMNR)?.toString();

    if (municipalityCountyNr && municipalityCountyNr?.length > 0) {
      const municipalityCountyId = parseInt(municipalityCountyNr);

      // Whether we zoom to Municipality or not depends on whether URL already has x,y,zoom params. KILDEN3-481
      const x = this._permaLinkService.getInitialValue(allowedQueryParams.X)?.toString();
      const y = this._permaLinkService.getInitialValue(allowedQueryParams.Y)?.toString();
      const zoom = this._permaLinkService.getInitialValue(allowedQueryParams.ZOOM)?.toString();
      const zoomMap: boolean = !(x?.length && y?.length && zoom?.length);

      // Municipality id is 4 digits
      if (municipalityCountyNr?.length >= 4) {
        // If search field empty and municipality lookup matches, fill the search field with name+id of muni
        this._searchService
          .getMunicipalityName(municipalityCountyNr)
          .pipe(
            take(1),
            tap((name: string) => {
              if (name?.length > 0) {
                if (this.searchTermCtrl && !this.searchTermCtrl.value?.length) {
                  this.searchTermCtrl.setValue(name + ' kommune (' + municipalityCountyId + ')');
                }
              }
            })
          )
          .subscribe();

        // Draw the border around the municipality
        this._searchService
          .getMunicipalityCountyBorder(this._mapService.getCode(), municipalityCountyNr, 'kommune')
          .pipe(
            take(1),
            tap(data => {
              this.showFeaturesInMap(data, undefined, zoomMap);
            })
          )
          .subscribe();
      } else if (municipalityCountyNr?.length === 2) {
        // Lookup county, if found fill the search field with name+id of county
        this._searchService
          .getCountyName(municipalityCountyNr)
          .pipe(
            take(1),
            tap((name: string) => {
              if (name?.length > 0) {
                if (this.searchTermCtrl && !this.searchTermCtrl.value?.length) {
                  this.searchTermCtrl.setValue(name + ' fylke (' + municipalityCountyId + ')');
                }
              }
            })
          )
          .subscribe();

        // Draw the border around the County
        this._searchService
          .getMunicipalityCountyBorder(this._mapService.getCode(), municipalityCountyNr, 'fylke')
          .pipe(
            take(1),
            tap(data => {
              this.showFeaturesInMap(data, undefined, zoomMap);
              this._permaLinkService.setParamReplaceHistory(allowedQueryParams.KOMNR, municipalityCountyNr as string);
            })
          )
          .subscribe();
      }
    }
  }

  private _initSearch(): void {
    this.stateGroupOptions = this.stateGroupOptionsData.pipe(
      filter((data: SearchResponseData) => data !== undefined && Object.entries(data).length > 0),
      map((data: SearchResponseData) => {
        return this.processLocationData(data);
      })
    );
  }

  private _isFloat(val: number): boolean {
    return Number.isInteger(val) || Number.isFinite(val);
  }

  /**
   * Add searched coordinate to its own layer, and zoom map to it
   */
  private _jumpToCoordinate(parsed: ParsedCoordinate): Observable<any> {
    const mapProjection = this._mapService.getMap().getView().getProjection();
    const nrDigitsBeforeDecimal = Math.floor(parsed.xy[0]).toString().length;

    let projectedCoord: Coordinate;
    const projectedExtent = transformExtent(AppConfig.EXTENT_NORWAY_MAINLAND, 'EPSG:25833', mapProjection);

    if (nrDigitsBeforeDecimal > 2) {
      // UTM-coordinates with 5+ digits before decimal
      let coordProjection = new Projection({ code: 'EPSG:25833' });
      if (parsed.utm) {
        coordProjection = new Projection({ code: 'EPSG:' + parsed.utm.padStart(5, '25833') });
      } else {
        coordProjection = mapProjection;
      }
      projectedCoord = transform(parsed.xy, coordProjection, mapProjection);
    } else {
      // Less than 3 digits before decimal = Geographic coordinate
      // fromLonLat() assumes EPSG:4326 as is default for GPS/Geographic, convert to current mapProjection
      projectedCoord = fromLonLat(parsed.xy, mapProjection);
    }

    const point = new Point(projectedCoord);
    const pointFeature = new Feature(point);
    pointFeature.setId(this.COORDINATE_POINT_ID);

    // Debugging help: Display the actual extent used to check if point is in bounds
    // this._showNorwayExtent();

    const xLength = Math.floor(parsed.xy[0]).toString().length;
    const yLength = Math.floor(parsed.xy[1]).toString().length;
    if ((yLength === 2 && xLength < 2) || (yLength > 2 && xLength <= 2)) {
      // Wait until user has finished writing full coordinate
      return of(undefined);
    }

    // Check point within bounds of Norway
    if (!containsCoordinate(projectedExtent, projectedCoord)) {
      this._mapService.restoreDefaultView(this._activatedRoute);

      Swal.fire({
        text: 'Koordinatet ligger utenfor kartet',
        title: 'Tilbakemelding',
      });
      return of(undefined);
    }

    // Add new point to the coordinate layer
    const existing = this._themeLayersService.findBankLayer(this.COORDINATE_LAYER_ID);
    if (existing) {
      const src = (existing as VectorLayer<VectorSource>).getSource();
      if (src) {
        src.addFeature(pointFeature);
      }
      this._themeLayersService.registerLayerChanges([
        {
          layerid: this.COORDINATE_LAYER_ID,
          change: { active: true, opacity: 1 },
        },
      ]);
    } else {
      const iconStyle = new OlStyleStyle({
        image: new OlStyleIcon({
          anchor: [0.45, 1.1],
          anchorOrigin: 'top-left',
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: './assets/location/kldn_pin_green.svg',
        }),
      });

      this._themeLayersService.registerNewLayer(
        this.COORDINATE_LAYER_ID,
        new VectorLayer({
          properties: { altitudeMode: 'clampToGround' },
          zIndex: AppConfig.ZINDEX_SEARCH,
          source: new VectorSource<Feature<Geometry>>({ features: [pointFeature] }),
          style: function (feature, resolution) {
            (iconStyle.getImage() as OlStyleImage).setScale(1 / Math.pow(resolution, 1 / 10));
            return iconStyle;
          },
        })
      );
    }

    this._mapService.getMap().getView().setCenter(projectedCoord);
    this._mapService.getMap().getView().setZoom(13);
    this._mapService.getMap().render();

    return of(undefined);
  }

  private _parseAsCoordinate(term: string | null | undefined): ParsedCoordinate {
    const response: ParsedCoordinate = { valid: false, xy: [] };
    if (!term) {
      return response;
    }

    // Regex previously used by ar5web:
    // const validUTMString = /^[ 0-9,;]{16,18}$/.test(term);
    // console.log(`validUTMString`, validUTMString);

    // Catch UTM with @-syntax: 7302545,382491@25833
    if (term.indexOf('@') > -1) {
      const utmSegments = term.split(/@+/);
      response.utm = utmSegments[utmSegments.length - 1];
      term = utmSegments[0];
    }

    // Catch UTM with semicolon-syntax: 7302545;382491;33 or 7302545,382491;33
    const nrSemiColons = (term.match(/;/g) || []).length;
    if (nrSemiColons === 1 || nrSemiColons === 2) {
      const utmSegments = term.split(/;+/);
      response.utm = utmSegments[utmSegments.length - 1];
      term = nrSemiColons === 2 ? utmSegments[0] + ';' + utmSegments[1] : utmSegments[0];
    }

    /**
     * Catch UTM with spaced-syntax: 7793793,11 1139451,61 32
     * Valid UTM coordinates have at least 5 digits before decimal point
     */
    const spacesSyntax = term.match(/(\d{5,}[.,\d]*)\s(\d{5,}[.,\d]*)\s?(\d*)/);
    if (spacesSyntax) {
      if (spacesSyntax[3] !== undefined && spacesSyntax[3]?.length > 1) {
        response.utm = spacesSyntax[3];
      }
      term = spacesSyntax[1].replace(',', '.') + ';' + spacesSyntax[2].replace(',', '.');
    }

    // Split into x/y
    const segments = term.split(/[,;]+/);
    if (segments.length !== 2) {
      // console.log(`unexpected segments.length`, segments);
      return response;
    }

    const f1 = parseFloat(segments[0].trim());
    const f2 = parseFloat(segments[1].trim());

    if (!isNaN(f1) && !isNaN(f2) && this._isFloat(f1) && this._isFloat(f2)) {
      response.valid = true;
      response.xy = [f2, f1] as Coordinate;
    }

    return response;
  }

  private _showNorwayExtent(mapProjection?: Projection) {
    const existing = this._themeLayersService.findBankLayer('test');
    if (existing) {
      LayerHelper.removeVectorFeatures((existing as VectorLayer<any>).getSource());
    }

    if (!mapProjection) {
      mapProjection = this._mapService.getMap().getView().getProjection();
    }

    const norwayExtent = AppConfig.EXTENT_NORWAY_MAINLAND;
    if (norwayExtent) {
      const bottomLeft = transform(getBottomLeft(norwayExtent), 'EPSG:25833', mapProjection);
      const topRight = transform(getTopRight(norwayExtent), 'EPSG:25833', mapProjection);
      const bottomRight = transform(getBottomRight(norwayExtent), 'EPSG:25833', mapProjection);
      const topLeft = transform(getTopLeft(norwayExtent), 'EPSG:25833', mapProjection);

      const poly = new Polygon([
        [
          [bottomLeft[0], bottomLeft[1]],
          [topLeft[0], topLeft[1]],
          [topRight[0], topRight[1]],
          [bottomRight[0], bottomRight[1]],
        ],
      ]);
      const polyFeature = new Feature(poly);
      polyFeature.setId('poly');

      if (existing) {
        (existing as VectorLayer<any>).getSource().addFeature(polyFeature);
      } else {
        this._themeLayersService.registerNewLayer(
          'test',
          new VectorLayer({
            properties: { altitudeMode: 'clampToGround' },
            zIndex: AppConfig.ZINDEX_SEARCH + 1,
            source: new VectorSource<Feature<Geometry>>({ features: [polyFeature] }),
            style: new OlStyleStyle({
              stroke: new OlStyleStroke({
                color: [11, 11, 255, 1],
                width: 5,
              }),
              fill: new OlStyleFill({
                color: [50, 50, 50, 0.5],
              }),
            }),
          })
        );
      }
    }
  }
}

const BORDER_ID = 'borderVectorLayer';

export type ParsedCoordinate = { utm?: string; valid: boolean; xy: Coordinate };

export enum SearchCategoriesEnum {
  COUNTY = 'Fylke/kommune',
  PLACE = 'Stedsnavn',
  ADDRESS = 'Adresse/matrikkel',
  DEER_DISTRICT = 'Reinbeitedistrikt',
}
