<!-- First time code goes in here, ng-template vil not run before they are called -->
<ng-container *ngIf="catalogTree">
  <ng-container
    *ngTemplateOutlet="
      treeTemplate;
      context: { $implicit: catalogTree, role: 'tree', parentid: 'root' }
    "></ng-container>
</ng-container>

<ng-template #treeTemplate let-nodes let-role="role" let-parentid="parentid">
  <ul [attr.role]="role" tabindex="-1">
    <li
      *ngFor="let item of nodes; let i = index"
      [id]="item.id + item.label"
      [ngClass]="{
        'border-layer-level': item.category === 'layer',
        li: !item.firstLevel,
        'li-first-level': item.firstLevel,
      }"
      draggable="false"
      role="treeitem"
      tabindex="0">
      <ng-container *ngTemplateOutlet="nodeTemplate; context: { $implicit: item }"> </ng-container>

      <ng-container *ngIf="item.children && item.children.length > 0 && item.expanded">
        <ng-container
          *ngTemplateOutlet="
            treeTemplate;
            context: {
              $implicit: item.children,
              role: 'group',
              parentid: item.id + item.label,
            }
          ">
        </ng-container>
      </ng-container>
    </li>
  </ul>
</ng-template>
