import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ArrayHelper {
  /**
   * Use this as callback for .sort() to easily sort by multiple fields
   *
   * @param fields Array of field (property) names to sort by.
   *    Prefix fieldname with dash to sort descending.
   *    Prefix fieldname with exclamation mark to only check for existence
   *    Combine them in order -!
   *
   * @example Order by topSpeed desc, then by price desc. List only those with a price.
   * fastestCarsForSale = cars.sort(fieldSorter(['-topSpeed', '-!price']));
   */
  static fieldSorter(fields: string[]) {
    return (a: any, b: any) =>
      fields
        .map(o => {
          let dir = 1;
          if (o[0] === '-') {
            dir = -1;
            o = o.substring(1);
          }

          if (o[0] === '!') {
            o = o.substring(1);
            return a[o] && !b[o] ? dir : !a[o] && b[0] ? -dir : 0;
          }

          return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
        })
        .reduce((p, n) => (p ? p : n), 0);
  }

  static moveArrayElement(haystack: any[], fromIdx: number, toIdx: number): void {
    haystack.splice(toIdx, 0, haystack.splice(fromIdx, 1)[0]);
  }

  static moveDown<T>(haystack: T[], needle: T): void {
    const originalIdx = haystack.indexOf(needle);
    if (originalIdx < 0 || originalIdx === haystack.length) {
      // Not in array or already at end
      return;
    }

    ArrayHelper.moveArrayElement(haystack, originalIdx, originalIdx + 1);
  }

  static moveUp<T>(haystack: T[], needle: T): void {
    const originalIdx = haystack.indexOf(needle);
    if (originalIdx < 1) {
      // Not in array or already at start
      return;
    }

    ArrayHelper.moveArrayElement(haystack, originalIdx, originalIdx - 1);
  }

  /**
   * Find needle in haystack, nested
   */
  static nestedFind<T extends Record<string, any>>(
    haystack: T[],
    needle: any,
    compareKey = 'id',
    nestingKey = 'children'
  ) {
    return haystack.reduce(function f(acc, node) {
      return compareKey in node && node[compareKey] === needle
        ? node
        : node[nestingKey] && node[nestingKey].length
          ? node[nestingKey].reduce(f, acc)
          : acc;
    }, haystack);
  }

  /**
   * If needle exists in haystack, splice it out
   */
  static removeIfExists<T>(haystack: T[], needle: T, compareProperty = 'id'): boolean {
    const idx = haystack.findIndex(
      // @ts-ignore
      stack => stack[compareProperty] === needle[compareProperty]
    );

    if (idx === undefined || idx === -1) {
      return false;
    }

    haystack.splice(idx, 1);
    return true;
  }
}
