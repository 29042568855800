import { MediaMatcher } from '@angular/cdk/layout';
import { Component, effect, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiConfig } from '@kildenconfig/api-config';
import { AppConfig } from '@kildenconfig/app.config';
import { ArrayHelper } from '@kildencore/helpers/array.helper';
import { LayerHelper } from '@kildencore/helpers/layer.helper';
import { CatalogTreeService } from '@kildencore/services/data/catalog-tree.service';
import { LinksService } from '@kildencore/services/data/links.service';
import { TopicsService } from '@kildencore/services/data/topics.service';
import { DialogService, DialogVariants } from '@kildencore/services/dialog.service';
import { KeyboardService } from '@kildencore/services/keyboard.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapPrintService } from '@kildencore/services/map-print.service';
import { MapService } from '@kildencore/services/map.service';
import { allowedQueryParams, PermaLinkService } from '@kildencore/services/perma-link.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { UploadService } from '@kildencore/services/upload.service';
import { CatalogTreeItem } from '@kildenshared/components/catalog-tree/catalog-tree-item';
import { CatalogTreeRoot } from '@kildenshared/components/catalog-tree/catalog-tree-root';
import { Kilden3DialogComponent } from '@kildenshared/components/dialog/dialog.component';
import { DrawComponent } from '@kildenshared/components/draw/draw.component';
import { PrintformComponent } from '@kildenshared/components/printform/printform.component';
import { ReportComponent } from '@kildenshared/components/report/report.component';
import { SearchWildlifeComponent } from '@kildenshared/components/search-wildlife/search-wildlife.component';
import { SonegrenseComponent } from '@kildenshared/components/sonegrense/sonegrense.component';
import { TopicIdsEnum } from '@kildenshared/constants/topic-ids.enum';
import { TopicConfig } from '@kildenshared/interfaces/topic-config';
import { filter, finalize, Subject, take, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'kilden3-header',
  templateUrl: './kilden-header.component.html',
  styleUrls: ['./kilden-header.component.css'],
})
export class KildenHeaderComponent implements OnInit, OnDestroy {
  // decoratortype('id on directive') variabelname !=is ok to not give value: type
  @ViewChild('matMenuTriggerMobile')
  matMenuTriggerMobile!: MatMenuTrigger;

  @ViewChild('menuTrigger')
  menuTrigger!: MatMenuTrigger;
  collapsedHeight: string = '60px';
  currentTopicConfig: any = {};
  currentTopicId = '';
  expandedHeight: string = '50px';
  isActive: boolean = false;
  isLayerSwipeActive: boolean = false;
  margin: number = 11;
  panelOpenState: boolean = false;
  topicConfig: TopicConfig[] = [];

  // Expose signal to template
  readonly $is3dActive = this._mapService.$is3dActive;

  // Start value from routeprovider
  public name: string = 'Kilden';
  public title: string = 'Kilden';
  public logo: string = '';
  public logo2: string = '';
  public logo3: string = '';
  public logo4: string = '';
  public logo5: string = '';
  public logoUrl: string = '';
  // End routeprovider

  private readonly _onDestroy$ = new Subject<void>();

  private _mobileScreen: MediaQueryList | undefined;

  constructor(
    private readonly _catalogTreeService: CatalogTreeService,
    private readonly _dialogService: DialogService,
    private readonly _keyboardService: KeyboardService,
    private readonly _kildenStateService: KildenStateService,
    private readonly _linksService: LinksService,
    private readonly _mapService: MapService,
    private readonly _mapPrintService: MapPrintService,
    private readonly _mediaMatcher: MediaMatcher,
    private readonly _permaLinkService: PermaLinkService,
    private readonly _route: ActivatedRoute,
    private readonly _themeLayersService: ThemeLayersService,
    private readonly _title: Title,
    private readonly _topicsService: TopicsService,
    private readonly _uploadService: UploadService,
    public dialog: MatDialog
  ) {
    effect(() => {
      if (this.$is3dActive()) {
        // If 3D becomes active, close print dialog as it's not available in 3D
        if (this._dialogService.isOpen(DialogVariants.PRINT)) {
          this._dialogService.dismissDialog(DialogVariants.PRINT);
        }

        // Also turn off layer swipe
        this.isLayerSwipeActive = false;
        this._kildenStateService.changeLayerSwipeActive(this.isLayerSwipeActive);
      }
    });
  }

  ngOnInit() {
    // START initvalues routeprovider from routing.module.ts ref kildenproject
    const config = this._route.snapshot.data['config'];
    this.title = config.title ? config.title : environment.title;
    this.logo = config.logo;
    this.logo2 = config.logo2;
    this.logo3 = config.logo3;
    this.logo4 = config.logo4;
    this.logo5 = config.logo5;
    this.logoUrl = config.logoUrl;
    this.name = config.name;
    // END

    this._setupSubscriptions();

    const urlTool = this._permaLinkService.getInitialValue(allowedQueryParams.TOOL);
    const urlTopic = this._permaLinkService.getInitialValue(allowedQueryParams.TOPIC);
    if (urlTool?.toString()?.length && urlTool !== 'info') {
      switch (urlTool.toString()) {
        case 'draw':
          this.openDrawDialog();
          break;

        case 'report':
          const topicStr = urlTopic?.toString();
          if (topicStr?.length) {
            if (topicStr === TopicIdsEnum.AREA) {
              this.openAreaReportDialog();
            } else if (topicStr === TopicIdsEnum.FOREST) {
              this.openForestReportDialog();
            } else if (topicStr === TopicIdsEnum.SOIL) {
              this.openSoilReportDialog();
            }
          }
          break;
      }
    }

    // If 'compare_ratio' or legacy 'swipe_ratio' value present in url, initialize LayerSwipe shortly after load
    let urlRatio = this._permaLinkService.getInitialValue(allowedQueryParams.COMPARE_RATIO);
    const legacyUrlRatio = this._permaLinkService.getInitialValue('swipe_ratio');
    if (legacyUrlRatio?.toString()?.length && !urlRatio?.toString()?.length) {
      urlRatio = legacyUrlRatio;
    }
    if (urlRatio) {
      setTimeout(() => {
        this.openLayerSwipe(urlRatio as string);
      }, 1000);
    }

    // Strip from permaLink layers that are not persisted, such as user drawn or uploaded
    const urlLayers = this._permaLinkService.getInitialValue(allowedQueryParams.LAYERS)?.toString();
    const urlLayersOpacity = this._permaLinkService.getInitialValue(allowedQueryParams.LAYERS_OPACITY)?.toString();
    const urlLayersVisibility = this._permaLinkService
      .getInitialValue(allowedQueryParams.LAYERS_VISIBILITY)
      ?.toString();

    if (urlLayers?.length) {
      const opacityArr: string[] = urlLayersOpacity?.split(AppConfig.QP_SEPARATOR_PRIMARY) || [];
      const visibilityArr: string[] = urlLayersVisibility?.split(AppConfig.QP_SEPARATOR_PRIMARY) || [];
      const newLayers: string[] = [];

      urlLayers.split(AppConfig.QP_SEPARATOR_PRIMARY).forEach((layerId, idx) => {
        if (LayerHelper.isUserProvided(layerId)) {
          opacityArr.splice(idx, 1);
          visibilityArr.splice(idx, 1);
          this._permaLinkService.setParamReplaceHistory(
            allowedQueryParams.LAYERS_OPACITY,
            opacityArr.join(AppConfig.QP_SEPARATOR_PRIMARY)
          );
          this._permaLinkService.setParamReplaceHistory(
            allowedQueryParams.LAYERS_VISIBILITY,
            visibilityArr.join(AppConfig.QP_SEPARATOR_PRIMARY)
          );
        } else {
          newLayers.push(layerId);
        }
      });

      this._permaLinkService.setParamReplaceHistory(
        allowedQueryParams.LAYERS,
        newLayers.join(AppConfig.QP_SEPARATOR_PRIMARY)
      );
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  /**
   * [getPermalink make link and show dialog]
   * @return [permalink]
   */
  getPermalink() {
    this.toggleHamburgerPanel();
    const epsg = `epsg=${this._mapService.getCode().split(':')[1]}`;
    navigator.clipboard.writeText(window.location.href.replace('epsg=3857', epsg));
    Swal.fire({
      title: 'Linken har blitt kopiert',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  /**
   * [HostListener if window resizes, we clean up all mat-menu]
   * @param  "window
   * @param  []
   * @return [void]
   */
  @HostListener('window:resize', [])
  onResize() {
    this.matMenuTriggerMobile.closeMenu();
    if (this.panelOpenState) {
      this.toggleHamburgerPanel();
    }
  }

  openAreaReportDialog() {
    this._dialogService.dismissAllDialogs([DialogVariants.AREA_REPORT]);
    this._kildenStateService.changeLayerSwipeActive(false);

    if (this._dialogService.isOpen(DialogVariants.AREA_REPORT)) {
      return;
    }

    this._dialogService.openDialog({
      variant: DialogVariants.AREA_REPORT,
      dialogHandle: this.dialog.open(Kilden3DialogComponent, {
        autoFocus: false, // also auto scrolls
        data: {
          title: 'Arealrapport',
          component: ReportComponent,
          reportType: 'areaReport',
        },
        disableClose: true, // Dont want the dialog closing if user clicks Escape mid-drawing
        hasBackdrop: false,
        height: 'auto',
        panelClass: 'report-dialog',
        position: { top: '65px', left: '5px' },
        width: '400px',
      }),
      keyboardService: this._keyboardService,
    });
  }

  // Functions for Contructions item
  openDrawDialog() {
    this._dialogService.dismissAllDialogs([DialogVariants.DRAW, DialogVariants.WILDLIFE]);

    if (this._dialogService.isOpen(DialogVariants.DRAW)) {
      return;
    }

    this.toggleHamburgerPanel();

    this._dialogService.openDialog({
      variant: DialogVariants.DRAW,
      dialogHandle: this.dialog.open(Kilden3DialogComponent, {
        autoFocus: false, // also auto scrolls
        data: {
          title: 'Tegne/måle',
          component: DrawComponent,
          submitText: 'Tegne',
          footer: false,
        },
        disableClose: true, // Dont want the draw dialog closing if user clicks Escape mid-drawing
        hasBackdrop: false,
        height: 'auto',
        position: {
          top: '65px',
          left: this._mobileScreen?.matches ? '0' : '344px',
        },
        width: this._mobileScreen?.matches ? '303px' : '450px',
      }),
      keyboardService: this._keyboardService,
    });
  }

  openForestReportDialog() {
    this._dialogService.dismissAllDialogs([DialogVariants.FOREST_REPORT]);
    this._kildenStateService.changeLayerSwipeActive(false);

    if (this._dialogService.isOpen(DialogVariants.FOREST_REPORT)) {
      return;
    }

    this._dialogService.openDialog({
      variant: DialogVariants.FOREST_REPORT,
      dialogHandle: this.dialog.open(Kilden3DialogComponent, {
        autoFocus: false, // also auto scrolls
        data: {
          title: 'Skograpport',
          component: ReportComponent,
          reportType: 'forestReport',
        },
        disableClose: true, // Dont want the dialog closing if user clicks Escape mid-drawing
        hasBackdrop: false,
        height: 'auto',
        panelClass: 'report-dialog',
        position: { top: '65px', left: '5px' },
        width: '400px',
      }),
      keyboardService: this._keyboardService,
    });
  }

  openGrantZonesDialog() {
    this._dialogService.dismissAllDialogs([DialogVariants.GRANT_ZONES]);

    if (this._dialogService.isOpen(DialogVariants.GRANT_ZONES)) {
      return;
    }

    this._dialogService.openDialog({
      variant: DialogVariants.GRANT_ZONES,
      dialogHandle: this.dialog.open(Kilden3DialogComponent, {
        autoFocus: false, // also auto scrolls
        data: {
          title: 'Finn sonegrense for distriktstilskudd',
          component: SonegrenseComponent,
          submitText: 'Søk..',
          footer: false,
        },
        hasBackdrop: false,
        height: 'auto',
        position: { top: '65px', left: '344px' },
        width: '500px',
      }),
      keyboardService: this._keyboardService,
    });
  }

  /**
   * Open LayerSwipe if prerequisites in place, else show warn and turn off
   */
  openLayerSwipe(urlRatio?: string): void {
    if (!this._themeLayersService.getActiveThemeLayers().length) {
      this.isLayerSwipeActive = false;
      Swal.fire({
        title: 'Ingen aktive kartlag',
        html: 'Har ingen kartlag å sammenlikne - aktiver kartlag og gjenåpne sammenlikningen',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      this.isLayerSwipeActive = true;
    }

    if (urlRatio && this.isLayerSwipeActive) {
      this._kildenStateService.changeLayerSwipeRatio(urlRatio);
    } else {
      this._kildenStateService.changeLayerSwipeActive(this.isLayerSwipeActive);
    }
  }

  openPrintDialog() {
    if (this._dialogService.isOpen(DialogVariants.PRINT)) {
      return;
    }

    this._kildenStateService.changeLayerSwipeActive(false);

    this._dialogService.openDialog({
      variant: DialogVariants.PRINT,
      dialogHandle: this.dialog.open(Kilden3DialogComponent, {
        autoFocus: false, // also auto scrolls
        data: {
          title: 'Lagre og skriv ut',
          component: PrintformComponent,
        },
        hasBackdrop: false,
        height: 'auto',
        position: { top: '65px', left: '12px' },
        width: '275px',
      }),
      keyboardService: this._keyboardService,
    });

    this._mapPrintService.showPrintBox();

    this._dialogService
      .getHandle(DialogVariants.PRINT)
      ?.afterClosed()
      .pipe(
        take(1),
        finalize(() => {
          this._mapPrintService.hidePrintBox();
        })
      )
      .subscribe();
  }

  openSoilReportDialog() {
    this._dialogService.dismissAllDialogs([DialogVariants.SOIL_REPORT]);
    this._kildenStateService.changeLayerSwipeActive(false);

    if (this._dialogService.isOpen(DialogVariants.SOIL_REPORT)) {
      return;
    }

    this._dialogService.openDialog({
      variant: DialogVariants.SOIL_REPORT,
      dialogHandle: this.dialog.open(Kilden3DialogComponent, {
        autoFocus: false, // also auto scrolls
        data: {
          title: 'Arealrapport jordsmonn',
          component: ReportComponent,
          reportType: 'soilReport',
        },
        disableClose: true, // Dont want the dialog closing if user clicks Escape mid-drawing
        hasBackdrop: false,
        height: 'auto',
        panelClass: 'report-dialog',
        position: { top: '65px', left: '5px' },
        width: '400px',
      }),
      keyboardService: this._keyboardService,
    });
  }

  openUploadDialog() {
    if (this._dialogService.isOpen(DialogVariants.UPLOAD)) {
      Swal.fire({
        title: ApiConfig.defaultWarnHeader,
        text: 'Opplastingsdialogen er allerede åpen',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }

    const uploadModalHandle = this._uploadService.openDialog();
    this._dialogService.openDialog({
      variant: DialogVariants.UPLOAD,
      dialogHandle: uploadModalHandle,
      keyboardService: this._keyboardService,
    });
  }

  openWildlifeDialog() {
    this._dialogService.dismissAllDialogs([DialogVariants.WILDLIFE]);
    this._kildenStateService.changeLayerSwipeActive(false);

    if (this._dialogService.isOpen(DialogVariants.WILDLIFE)) {
      return;
    }

    this._dialogService.openDialog({
      variant: DialogVariants.WILDLIFE,
      dialogHandle: this.dialog.open(Kilden3DialogComponent, {
        autoFocus: false, // also auto scrolls
        data: {
          title: 'Søk dyr',
          component: SearchWildlifeComponent,
          footer: false,
        },
        hasBackdrop: false,
        height: 'auto',
        panelClass: 'wildlife',
        position: {
          top: '65px',
          left: this._mobileScreen?.matches ? '45px' : '344px',
        },
        width: this._mobileScreen?.matches ? '227px' : 'auto',
      }),
      keyboardService: this._keyboardService,
    });
  }

  preventDrop(e: any) {
    if (e.type === 'dragenter') {
      this.menuTrigger.closeMenu();
    }
    e.preventDefault();
    e.dataTransfer.effectAllowed = 'none';
    e.dataTransfer.dropEffect = 'none';
  }

  /**
   * Utility-function for checking if tool with given ID is part of
   * current topics toolList
   * @param  toolid               tool id to check
   * @return        true if tool should be shown, false otherwise
   */
  shouldShowTool(toolid: string): boolean {
    if (!this.currentTopicConfig?.toolList) {
      return false;
    }
    return this.currentTopicConfig.toolList.indexOf(toolid) !== -1;
  }

  /**
   * Utility-function for checking if tool with given ID is part of
   * current topics toolMobileList
   * @param  toolid               tool id to check
   * @return        true if tool should be shown, false otherwise
   */
  shouldShowToolMobile(toolid: string): boolean {
    if (!this.currentTopicConfig?.toolMobileList) {
      return false;
    }
    return this.currentTopicConfig.toolMobileList.indexOf(toolid) !== -1;
  }

  /**
   * [toggleHamburgerPanel we control hamburgermeny from here]
   * @return [void]
   */
  toggleHamburgerPanel() {
    if (!this._mobileScreen?.matches) {
      return;
    }

    this.panelOpenState = !this.panelOpenState;
  }

  /**
   * Toggle LayerSwipe opposite of current state
   */
  toggleLayerSwipe(): void {
    this.toggleHamburgerPanel();
    if (!this.isLayerSwipeActive) {
      this.openLayerSwipe();
    } else {
      this.isLayerSwipeActive = !this.isLayerSwipeActive;
      this._kildenStateService.changeLayerSwipeActive(this.isLayerSwipeActive);
    }
  }

  triggerFarmsMap(): void {
    this.toggleHamburgerPanel();
    const dismissPopoverAfterSeconds = 10;
    const farmsMapIdBod = 'eksterne_driftssenter';

    this.isActive = !this.isActive;
    setTimeout(() => (this.isActive = false), 1000 * dismissPopoverAfterSeconds);

    this._catalogTreeService.catalogTree$
      .pipe(
        take(1),
        filter(tree => tree?.children?.length > 0),
        tap((tree: CatalogTreeRoot) => {
          const treeItem: CatalogTreeItem = ArrayHelper.nestedFind(tree.children, farmsMapIdBod, 'idBod', 'children');
          if (treeItem?.idBod) {
            treeItem.selectedOpen = treeItem.visible = true;

            this._themeLayersService.registerLayerChanges([
              {
                layerid: treeItem.idBod,
                change: {
                  active: true,
                  visible: true,
                },
              },
            ]);
          }
        })
      )
      .subscribe();
  }

  private _initializeTools(id: string) {
    if (this.topicConfig.length !== 0) {
      this.currentTopicConfig = this.topicConfig.find((t: any) => t.id === id);
    }
  }

  private _wildLifeDialogReopen(): void {
    const dialogWl = this._dialogService.getHandle(DialogVariants.WILDLIFE);
    if (dialogWl) {
      dialogWl.close();
    }
    // Wait for system to close existing so the DialogService can report dialog as closed
    setTimeout(() => {
      this.openWildlifeDialog();
    }, 300);
  }

  private _setupSubscriptions(): void {
    this._kildenStateService.deviceSize$
      .pipe(
        tap(newTopic => {
          const wasMobileScreen: boolean = !!this._mobileScreen?.matches;
          this._mobileScreen = this._mediaMatcher.matchMedia('(max-width: 1025px)');

          // If resize caused us to switch between mobile and desktop, reopen dialog
          if (
            this._dialogService.isOpen(DialogVariants.WILDLIFE) &&
            wasMobileScreen !== !!this._mobileScreen?.matches
          ) {
            this._wildLifeDialogReopen();
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._kildenStateService.deviceOrientation$
      .pipe(
        tap(newTopic => {
          this._mobileScreen = this._mediaMatcher.matchMedia('(max-width: 1025px)');

          if (this._dialogService.isOpen(DialogVariants.WILDLIFE)) {
            this._wildLifeDialogReopen();
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._kildenStateService.layerSwipeActive$
      .pipe(
        tap(active => {
          this.isLayerSwipeActive = active;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._kildenStateService.topic$
      .pipe(
        tap(newTopic => {
          if (newTopic !== this.currentTopicId) {
            if (this.currentTopicId) {
              this._dialogService.dismissAllDialogs();
            }

            this.currentTopicId = newTopic;
            this._linksService.fetchInformationLinksByTopic(this.currentTopicId);
          }
          this._mobileScreen = this._mediaMatcher.matchMedia('(max-width: 1025px)');

          if (newTopic === 'sonerfordistriktstilskudd' && !this._mobileScreen.matches) {
            this.openGrantZonesDialog();
          }

          if (newTopic === TopicIdsEnum.WILDLIFE) {
            this.openWildlifeDialog();
          }

          // initialize tools configuration when new topic is received
          this.updateTitle(newTopic);
          this._initializeTools(newTopic);
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._topicsService.topicsConfig$
      .pipe(
        tap(newConfig => {
          this.topicConfig = newConfig;
          // initialize tool-book-keeping variables if new topic is set
          if (this.currentTopicId !== '') {
            this._initializeTools(this.currentTopicId);
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  /**
   * Sets new page title, optionally with appended text
   */
  private updateTitle(appendText?: string): void {
    let newTitle = this.title;
    if (appendText?.length) {
      newTitle += ' - ' + appendText;
    }
    this._title.setTitle(newTitle);
  }

  toggle3d(use3d: boolean): void {
    this._mapService.$is3dActive.set(use3d);
  }
}
