<button
  id="geolocation"
  type="button"
  geolocation
  geolocation-map="map"
  title="Vis min posisjon"
  class="btn"
  (click)="geolocate()"
  [ngClass]="{
    'geolocation-tracking': tracking,
    'geolocation-error': geoerr,
  }"></button>
