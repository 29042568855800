import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { AppConfig } from '@kildenconfig/app.config';
import { DrawHelper } from '@kildencore/helpers/draw.helper';
import { DrawService } from '@kildencore/services/draw.service';
import { KildenStateService } from '@kildencore/services/kilden-state.service';
import { MapService } from '@kildencore/services/map.service';
import { ThemeLayersService } from '@kildencore/services/theme-layers.service';
import { ColorsConst } from '@kildenshared/constants/draw/colors.const';
import { DrawToolsConst } from '@kildenshared/constants/draw/draw-tools.const';
import { PointSymbolSizesConst } from '@kildenshared/constants/draw/point-symbol-sizes.const';
import { PointSymbolsConst } from '@kildenshared/constants/draw/point-symbols.const';
import { StrokeWidthsConst } from '@kildenshared/constants/draw/stroke-widths.const';
import { ToolIdsEnum } from '@kildenshared/constants/tool-ids.enum';
import { ColorType } from '@kildenshared/types/draw/color.type';
import { DrawType } from '@kildenshared/types/draw/draw.type';
import { asString, Color } from 'ol/color';
import { ColorLike, PatternDescriptor } from 'ol/colorlike';
import Feature from 'ol/Feature';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'kilden3-draw',
  templateUrl: './draw.component.html',
  styleUrls: ['./draw.component.css'],
})
export class DrawComponent implements OnInit, OnDestroy {
  @Output()
  showMeasurement = true;

  colors = ColorsConst;
  drawTools = DrawToolsConst;
  pointSizes = PointSymbolSizesConst;
  pointSymbols = PointSymbolsConst;
  strokeWidths = StrokeWidthsConst;

  activeTab: DrawType = 'None'; // Which button/tab to highlight
  chosenTool: DrawType = 'None'; // Which tool to use in OpenLayers
  nrSelectedFeatures: number = 0;
  optionColor: ColorType = this.colors[0];
  optionSymbolSrc!: string;
  optionSymbolSize: number = this.pointSizes[2].scale;
  optionStrokeWidth = this.strokeWidths[1].value;
  optionText: string = '';
  optionUseFill = true;

  private readonly _onDestroy$ = new Subject<void>();

  constructor(
    private readonly _drawService: DrawService,
    private readonly _kildenStateService: KildenStateService,
    private readonly _mapService: MapService,
    private readonly _themeLayersService: ThemeLayersService
  ) {}

  changeShowMeasurement() {
    this.showMeasurement = !this.showMeasurement;
    this._drawService.changeMeasure(this.showMeasurement);
  }

  changeText() {
    const inText: string = this.optionText!;
    this._drawService.changeDrawText(inText);
  }

  changeUseFillColor(value: boolean) {
    this.optionUseFill = value;
    this._drawService.changeUseFillColor(this.optionUseFill);
  }

  /**
   * If provided a key, will set local current color based on key.
   * Will notify DrawService to set the drawing color, whether local changed or not.
   */
  chooseColor(colorKey?: string) {
    if (colorKey) {
      const idx = this.colors.findIndex(clr => clr.value === colorKey);
      if (idx > -1) {
        this.optionColor = this.colors[idx];
      }
    }

    this._drawService.changeDrawColor(this.optionColor.color);
  }

  chooseDrawTool(inTool: DrawType): void {
    if (this.is3dActive()) {
      this.activeTab = 'None';
      this.chosenTool = 'None';
      return;
    }

    this.activeTab = inTool;
    this.chosenTool = inTool;

    // Notify subscribers such as LeftComp (ActiveLayers should verify "Tegnede objekter" is in the list).
    this._kildenStateService.changeTool(ToolIdsEnum.DRAW);
    this._themeLayersService.registerLayerChanges([
      {
        layerid: AppConfig.IDBOD_USER_DRAWN,
        change: { active: true, visible: true },
      },
    ]);

    this._drawService.chooseDrawTool(inTool, AppConfig.IDBOD_USER_DRAWN);
    this.chooseColor();
  }

  chooseIcon(index: number) {
    this.optionSymbolSrc = 'assets/draw/' + this.pointSymbols[index].src;
    this._drawService.changeDrawIcon(this.optionSymbolSrc);
  }

  chooseLinewidth(width: string) {
    this.optionStrokeWidth = parseInt(width, 10);
    this._drawService.changeDrawStrokeWidth(this.optionStrokeWidth);
  }

  chooseSize() {
    this._drawService.changeDrawIconSize(this.optionSymbolSize);
  }

  deleteAllFeatures() {
    this._drawService.deleteAllFeatures();
  }

  deleteFeatures() {
    this._drawService.deleteSelectedFeatures();
  }

  is3dActive(): boolean {
    if (this._mapService.$is3dActive()) {
      this._drawService.endDrawInteractions();
      this.activeTab = 'None';
      this.chosenTool = 'None';
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this._drawService.endDrawInteractions();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    this._kildenStateService.changeTool(ToolIdsEnum.DRAW);
    this._setupSubscriptions();

    // Wait for prerequisites like drawLayer to become available
    setTimeout(() => {
      this._drawService.startDraw(AppConfig.IDBOD_USER_DRAWN);
      this._drawService.changeDrawColor(this.optionColor.color);
      this._drawService.changeDrawIconSize(this.optionSymbolSize);
      this.chooseDrawTool('Point');
    }, 600);
  }

  private _setupSubscriptions(): void {
    this._drawService.onSelectionChange$
      .pipe(
        tap(change => {
          this.nrSelectedFeatures = this._drawService.interactionSelect?.getFeatures()?.getLength() || 0;

          if (!change.selected?.length && change.deselected?.length) {
            this.chosenTool = this.activeTab;
          } else {
            this._chooseOptionsFromFeature(change.selected[0]);
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._themeLayersService.layersChange$
      .pipe(
        tap(changes => {
          changes.forEach(lc => {
            // Deselect active tool when Draw layer removed
            if (
              lc.layerid === AppConfig.IDBOD_USER_DRAWN &&
              (lc.change.active === false || lc.change.visible === false)
            ) {
              this.activeTab = 'None';
              this.chosenTool = 'None';
              this._drawService.endDrawInteractions();
              this._kildenStateService.changeTool(ToolIdsEnum.INFO);
            }
          });
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  private _chooseOptionsFromFeature(inFeature: Feature): void {
    if (this.activeTab !== 'Modify') {
      return;
    }

    const geoType = inFeature.getGeometry()?.getType();
    const multiModStyle: any = inFeature.getStyle();
    let modStyle: Style;
    if (Array.isArray(multiModStyle)) {
      modStyle = multiModStyle[0];
    } else {
      modStyle = multiModStyle;
    }
    const overlayId = AppConfig.IDBOD_USER_DRAWN + inFeature.getId()?.toString();
    const overlay = this._drawService.getMap()?.getOverlayById(overlayId);

    if (modStyle.getFill()) {
      this.optionColor = this._findColorInPalette(modStyle.getFill()?.getColor());
    } else if (modStyle.getStroke()) {
      this.optionColor = this._findColorInPalette(modStyle.getStroke()?.getColor());
    } else {
      this.optionColor = this.colors[0];
    }
    this._drawService.optionColor = this.optionColor.color;

    if (modStyle.getStroke()) {
      this.optionStrokeWidth = modStyle.getStroke()?.getWidth() || DrawHelper.STROKE_WIDTH;
      this._drawService.optionStrokeWidth = this.optionStrokeWidth;
    }

    if (modStyle.getImage() instanceof Icon) {
      this.chosenTool = 'Point';
      const pointSizeValue = modStyle.getImage()?.getScale();
      this.optionSymbolSize = Array.isArray(pointSizeValue)
        ? (pointSizeValue[0] as number)
        : (pointSizeValue as number);
      this._drawService.optionIconSize = this.optionSymbolSize;
    } else if (modStyle.getText()) {
      this.chosenTool = 'Text';
      this.optionColor = this._findColorInPalette(modStyle.getText()?.getFill()?.getColor());
      this.optionText = modStyle.getText()?.getText() as string;
      this._drawService.optionInputString = this.optionText;
    } else if (geoType === 'Polygon') {
      this.chosenTool = 'Polygon';
      this.optionUseFill = !!modStyle.getFill();
      const hidden = overlay?.getElement()?.parentElement?.getAttribute('hidden');
      this.showMeasurement = !(hidden === 'true'); // or (hidden === null)
      this._drawService.optionColorFill = this.optionColor.color;
      this._drawService.optionUseFillColor = this.optionUseFill;
    } else if (geoType === 'LineString') {
      this.chosenTool = 'LineString';
      const hidden = overlay?.getElement()?.parentElement?.getAttribute('hidden');
      this.showMeasurement = !(hidden === 'true'); // or (hidden === null)
    }
    this._drawService.optionShowMeasurement = this.showMeasurement;
  }

  private _findColorInPalette(value: Color | ColorLike | PatternDescriptor | string | null | undefined): ColorType {
    if (!value || value instanceof CanvasGradient || value instanceof CanvasPattern) {
      return this.colors[0];
    }

    let rgbValue!: string;
    if (!Array.isArray(value)) {
      rgbValue = DrawHelper.convertRgbaToRgb(value.toString());
      // value = asArray(value);
    } else if (value.length === 4) {
      value.pop();
      rgbValue = 'rgb(' + value.join(',') + ')';
    }

    const idx: number = this.colors.findIndex(clr => {
      return rgbValue === DrawHelper.convertRgbaToRgb(asString(clr.color));
    });

    return idx < 0 ? this.colors[0] : this.colors[idx];
  }
}
