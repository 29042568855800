import { ApiConfig } from '@kildenconfig/api-config';
import { DrawHelper } from '@kildencore/helpers/draw.helper';
import { BaseSerializer } from '@kildencore/serializer/base-serializer';
import { PrintTools } from '@kildencore/tools';
import { get, pickBy } from 'lodash';
import OlFeature from 'ol/Feature';
import OlFormatGeoJSON from 'ol/format/GeoJSON';
import { LineString } from 'ol/geom';
import OlGeometryCircle from 'ol/geom/Circle';
import Polygon, { fromCircle } from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import { Source } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import OlStyleCircle from 'ol/style/Circle';
import OlStyleFill from 'ol/style/Fill';
import OlStyleIcon from 'ol/style/Icon';
import OlStyleImage from 'ol/style/Image';
import OlStyleRegularShape from 'ol/style/RegularShape';
import OlStyleStroke from 'ol/style/Stroke';
import OlStyleStyle from 'ol/style/Style';
import OlStyleText from 'ol/style/Text';
// eslint-disable-next-line no-unused-vars
import * as parseColor from 'parse-color';
// eslint-disable-next-line no-unused-vars
import parseCSSFont, { IFont } from 'parse-css-font';

export class MapFishPrintVectorSerializer extends BaseSerializer {
  /**
   * The vector layer type identificator.
   */
  static TYPE_VECTOR: string = 'Vector';

  /**
   * The property to get the style dictionary key from.
   */
  static FEAT_STYLE_PROPERTY = '_style';

  constructor() {
    super();
  }

  override canSerialize(source: Source) {
    return source instanceof VectorSource;
  }
  /**
   * Serializes/Encodes the given layer.
   *
   * @abstract
   * @param {OlLayer} layer The layer to serialize/encode.
   * @param {Object} opts Additional properties to pass to the serialized
   *   layer object that can't be obtained by the layer itself. It can also be
   *   used to override all generated layer values, e.g. the image format. Only
   *   used in V3.
   * @param {number} viewResolution The resolution to calculate the styles for.
   * @return {Object} The serialized/encoded layer.
   */
  override serialize(layer: VectorLayer<VectorSource>, opts: any, viewResolution: any) {
    const source = layer.getSource();

    if (!source || !this.validateSource(source)) {
      return;
    }

    const features = source.getFeatures();
    const format = new OlFormatGeoJSON();
    const serializedFeatures: any = [];
    const serializedStyles: any = {};
    const serializedStylesDict: any = {};
    let styleName;
    let styleId = 0;

    features.forEach((feature: OlFeature) => {
      // This causes print errors and is not used for printing, temporarily remove to not include in serialized object.
      const cachedStyle = feature.get(DrawHelper.CACHED_STYLE_KEY);
      feature.unset(DrawHelper.CACHED_STYLE_KEY, false);

      const geometry = feature.getGeometry();
      const geometryType = geometry?.getType();
      let serializedFeature: any = [];

      // as GeoJSON format doesn't support circle geometries, we need to
      // transform circles to polygons.
      if (geometry instanceof OlGeometryCircle) {
        const style = feature.getStyle();
        const polyFeature = new OlFeature(fromCircle(geometry));
        polyFeature.setStyle(style);
        feature = polyFeature;
      }

      serializedFeature = format.writeFeatureObject(feature);

      let styles;
      let styleFunction = feature.getStyleFunction();

      if (styleFunction) {
        styles = styleFunction.call(null, feature, viewResolution);
      } else {
        styleFunction = layer.getStyleFunction();
        if (styleFunction) {
          styles = styleFunction.call(layer, feature, viewResolution);
        }
      }

      // assumption below: styles is an array of OlStyleStyle
      if (styles instanceof OlStyleStyle) {
        styles = [styles];
      }

      if (styles) {
        serializedFeatures.push(serializedFeature);

        styles.forEach((style: any) => {
          let styleObject = this.writeStyle(style, geometryType);
          if (feature.get('hasMeasurementOverlay') && feature.get('hasMeasurementOverlay') !== 'false') {
            const symText = MapFishPrintVectorSerializer.makeLabel(feature);
            styleObject = { ...styleObject, ...symText };
          }
          const serializedStyle = JSON.stringify(styleObject);
          const dictStyle = serializedStylesDict[serializedStyle];

          if (dictStyle >= 0) {
            styleName = dictStyle;
          } else {
            serializedStylesDict[serializedStyle] = styleName = styleId++;
            serializedStyles[styleName] = styleObject;
          }
          serializedStyles['styleProperty'] = MapFishPrintVectorSerializer.FEAT_STYLE_PROPERTY;
          if (!serializedFeature.properties) {
            serializedFeature.properties = {};
          }

          serializedFeature.properties[MapFishPrintVectorSerializer.FEAT_STYLE_PROPERTY] = styleName;
        });
      }

      // Restore the cached style now that serialization is complete
      feature.set(DrawHelper.CACHED_STYLE_KEY, cachedStyle);
    });

    return {
      name: layer.get('name') || 'Vector Layer',
      opacity: layer.getOpacity(),
      geoJson: {
        type: 'FeatureCollection',
        features: serializedFeatures,
      },
      // style is added because we have to customize to our mapfish
      style: serializedStyles,
      //  styleProperty: MapFishPrintVectorSerializer.FEAT_STYLE_PROPERTY, is moved inside Style
      type: MapFishPrintVectorSerializer.TYPE_VECTOR,
    };
  }

  /**
   * Returns a plain object matching the passed `ol.style.Style` instance.
   *
   * @param {OlStyleStyle} olStyle An ol.style.Style instance.
   * @param geomType
   * @return {Object} A plain object matching the passed `ol.style.Style`
   *                  instance.
   */
  writeStyle = (olStyle: OlStyleStyle, geomType: any) => {
    if (!olStyle) {
      return undefined;
    }
    const fillStyle: any = this.writeFillStyle(olStyle.getFill() as OlStyleFill);
    const imageStyle: any = this.writeImageStyle(olStyle.getImage() as OlStyleImage);
    const strokeStyle: any = this.writeStrokeStyle(olStyle.getStroke() as OlStyleStroke);
    const textStyle = this.writeTextStyle(olStyle.getText() as OlStyleText);
    const parsedColorStroke =
      typeof strokeStyle.color === 'string' ? parseColor(strokeStyle.color) : parseColor('#FF0000');
    const parsedColorFill = parseColor(fillStyle.color);
    const parsedColorGraphic = imageStyle.color ? parseColor(imageStyle.color) : parseColor('#FF0000');
    const onlyColorCode = parsedColorGraphic.hex ? parsedColorGraphic.hex.split('#')[1] : undefined;

    let style = {};

    switch (geomType) {
      case 'Point':
      case 'MultiPoint':
        style = {
          strokeColor: parseColor(get(imageStyle, 'stroke.color')).hex,
          strokeOpacity: get(parseColor(get(imageStyle, 'stroke.color')), 'rgba[3]'),
          strokeWidth: get(imageStyle, 'stroke.width'),
          strokeLinecap: get(imageStyle, 'stroke.lineCap'),
          strokeDashstyle: get(imageStyle, 'stroke.lineDash'),
          fillColor: parseColor(get(imageStyle, 'fill.color')).hex,
          fillOpacity: get(parseColor(get(imageStyle, 'fill.color')), 'rgba[3]'),
          pointRadius: imageStyle.radius,
          externalGraphic:
            this.checkImageType(imageStyle.src) === 'svg'
              ? ApiConfig.svgUrl + '?color=' + onlyColorCode + '&url=' + ApiConfig.guiUrl + '/' + imageStyle.src
              : imageStyle.src,
          graphicWidth: imageStyle.scale ? get(imageStyle, 'size[0]') * imageStyle.scale : undefined,
          graphicHeight: imageStyle.scale ? get(imageStyle, 'size[1]') * imageStyle.scale : undefined,
          graphicOpacity: imageStyle instanceof OlStyleIcon ? imageStyle.getOpacity() : undefined,
          // TODO not available in ol3?
          graphicXOffset: imageStyle.anchor ? get(imageStyle, 'anchor[0]') * imageStyle.scale : undefined,
          // TODO not available in ol3?
          graphicYOffset: imageStyle.anchor ? get(imageStyle, 'anchor[1]') * imageStyle.scale : undefined,
          // graphicXOffset	{Number} Pixel offset along the positive x-axis for displacing an external graphic.
          // graphicYOffset	{Number} Pixel offset along the positive y-axis for displacing an external graphic.

          rotation: get(imageStyle, 'rotation'),
          graphicName: get(imageStyle, 'graphicName') || 'circle',
        };
        break;
      case 'LineString':
      case 'MultiLineString':
        style = {
          strokeColor: parsedColorStroke.hex,
          strokeOpacity: get(parsedColorStroke, 'rgba[3]'),
          strokeWidth: strokeStyle.width,
          strokeLinecap: strokeStyle.lineCap,
          strokeDashstyle: strokeStyle.lineDash,
        };
        break;
      case 'Polygon':
      case 'MultiPolygon':
      case 'Circle':
        style = {
          strokeColor: parsedColorStroke.hex,
          strokeOpacity: get(parsedColorStroke, 'rgba[3]'),
          strokeWidth: strokeStyle.width,
          strokeLinecap: strokeStyle.lineCap,
          strokeDashstyle: strokeStyle.lineDash,
          fillColor: parsedColorFill.hex || '#00FFFFFF', // mapfish need defalut value or won't write polygon
          fillOpacity: get(parsedColorFill, 'rgba[3]') || 0, // because of the defalut on fillColor
        };
        break;
      default:
        // TODO some fallback style?!
        style = {};
    }

    if (textStyle && textStyle.font && textStyle.text) {
      const parsedFont = /** @type {IFont} */ parseCSSFont(textStyle.font) as IFont;
      let parsedFontfamily = '';
      if (parsedFont.family) {
        parsedFontfamily = parsedFont.family.join(',');
      }
      style = {
        ...style,
        label: textStyle.text,
        fontFamily: parsedFontfamily,
        fontSize: parsedFont.size,
        fontWeight: parsedFont.weight,
        fontStyle: parsedFont.style,
        fontColor: parseColor(get(textStyle, 'fill.color')).hex,
        fontOpacity: get(parseColor(get(textStyle, 'fill.color')), 'rgba[3]'),
        strokeOpacity: 0, // remove point between text
        labelOutlineColor: '#FFFFFF', // add white backround-color for text
        labelOutlineWidth: 1.5,
      };
    }
    return pickBy(style, v => v !== undefined);
  };

  checkImageType(url: string) {
    if (!url) return undefined;

    if (url.split('.').pop() === 'svg') return 'svg';

    return url.split('.').pop();
  }
  /**
   * Returns a plain object matching the passed ol.style.Image instance.
   *
   * Works with `ol.style.Circle`, `ol.style.Icon` and
   * `ol.style.RegularShape`
   *
   * @param {OlStyleImage} olImageStyle An ol.style.Image instance.
   * @return {Object} A plain object matching the passed `ol.style.Image`
   *                  instance.
   */
  writeImageStyle = (olImageStyle: OlStyleImage) => {
    if (!olImageStyle) {
      return {};
    }

    if (olImageStyle instanceof OlStyleCircle) {
      return this.writeCircleStyle(olImageStyle);
    }

    if (olImageStyle instanceof OlStyleIcon) {
      return this.writeIconStyle(olImageStyle);
    }

    if (olImageStyle instanceof OlStyleRegularShape) {
      return this.writeRegularShapeStyle(olImageStyle);
    } else return {};
  };

  /**
   * Returns a plain object matching the passed ol.style.Circle instance.
   *
   * @param {OlStyleCircle} olCircleStyle An ol.style.Circle instance.
   * @return {Object} A plain object matching the passed `ol.style.Circle`
   *                  instance.
   */
  writeCircleStyle = (olCircleStyle: OlStyleCircle) => {
    if (!olCircleStyle) {
      return {};
    }

    return {
      fill: this.writeFillStyle(olCircleStyle.getFill() as OlStyleFill),
      image: olCircleStyle.getImage(1),
      opacity: olCircleStyle.getOpacity(),
      radius: olCircleStyle.getRadius(),
      rotateWithView: olCircleStyle.getRotateWithView(),
      rotation: olCircleStyle.getRotation(),
      scale: olCircleStyle.getScale(),
      stroke: this.writeStrokeStyle(olCircleStyle.getStroke() as OlStyleStroke),
    };
  };

  /**
   * Returns a plain object matching the passed ol.style.Icon instance.
   *
   * @param {OlStyleIcon} olIconStyle An ol.style.Icon instance.
   * @return {Object} A plain object matching the passed `ol.style.Icon`
   *                  instance.
   */
  writeIconStyle = (olIconStyle: OlStyleIcon) => {
    if (!olIconStyle) {
      return {};
    }

    // we check and convert
    const paramColor = this.checkFormatColor(olIconStyle.getColor());
    return {
      anchor: olIconStyle.getAnchor() || undefined,
      color: paramColor,
      // getAnchor() returns the anchor in pixel values always, hence
      // we need to set the anchorUnits respectively
      anchorXUnits: 'pixels',
      anchorYUnits: 'pixels',
      anchorOrigin: olIconStyle.getOrigin(),
      opacity: olIconStyle.getOpacity(),
      rotateWithView: olIconStyle.getRotateWithView(),
      rotation: olIconStyle.getRotation(),
      scale: olIconStyle.getScale(),
      size: olIconStyle.getSize(),
      src: olIconStyle.getSrc(),
    };
  };

  /**
   * checkFormatColor should return rgba(74, 123, 164, 0.3) or rgb(74, 123, 164)
   * not only [74, 123, 164, 0.3]
   * we check and convert
   * @return            [String]
   * @param paramColor
   */
  checkFormatColor = (paramColor: any) => {
    let color = paramColor;
    if (paramColor instanceof Array) {
      color = paramColor.length === 3 ? 'rgb' : 'rgba' + '(' + paramColor.toString() + ')';
    }
    return color;
  };

  /**
   * Returns a plain object matching the passed ol.style.RegularShape
   * instance.
   *
   * @param {OlStyleRegularShape} olRegularShape An ol.style.RegularShape
   *                                               instance.
   * @return {Object} A plain object matching the passed `ol.style.RegularShape`
   *                  instance.
   */
  writeRegularShapeStyle = (olRegularShape: OlStyleRegularShape) => {
    if (!olRegularShape) {
      return {};
    }

    /**
     * Returns the graphicName of a RegularShape or undefined based on the
     * number of points, radius and angle.
     *
     * @returns {string | undefined} The graphicName of a RegularShape feature
     *                                (triangle, square, cross, x and star)
     */
    const getGraphicName = () => {
      if (olRegularShape.getPoints() === 3) {
        return 'triangle';
      } else if (olRegularShape.getPoints() === 4 && olRegularShape.getRadius2() === undefined) {
        return 'square';
      } else if (
        olRegularShape.getPoints() === 4 &&
        olRegularShape.getRadius2() !== undefined &&
        olRegularShape.getAngle() === 0
      ) {
        return 'cross';
      } else if (olRegularShape.getPoints() === 4 && olRegularShape.getAngle() !== 0) {
        return 'x';
      } else if (olRegularShape.getPoints() === 5) {
        return 'star';
      } else {
        return undefined;
      }
    };

    return {
      angle: olRegularShape.getAngle(),
      fill: this.writeFillStyle(olRegularShape.getFill() as OlStyleFill),
      opacity: olRegularShape.getOpacity(),
      points: olRegularShape.getPoints(),
      radius: olRegularShape.getRadius(),
      radius2: olRegularShape.getRadius2(),
      rotateWithView: olRegularShape.getRotateWithView(),
      rotation: olRegularShape.getRotation(),
      scale: olRegularShape.getScale(),
      stroke: this.writeStrokeStyle(olRegularShape.getStroke() as OlStyleStroke),
      graphicName: getGraphicName(),
    };
  };

  /**
   * Returns a plain object matching the passed ol.style.Fill instance.
   *
   * @param {OlStyleFill} olFillStyle An ol.style.Fill instance.
   * @return {Object} A plain object matching the passed `ol.style.Fill`
   *                  instance.
   */
  writeFillStyle = (olFillStyle: OlStyleFill) => {
    if (!olFillStyle) {
      return {};
    }
    const paramColor = this.checkFormatColor(olFillStyle.getColor());
    return {
      color: paramColor,
    };
  };

  /**
   * Returns a plain object matching the passed ol.style.Stroke instance.
   *
   * @param {OlStyleStroke} olStrokeStyle An ol.style.Stroke instance.
   * @return {Object} A plain object matching the passed `ol.style.Stroke`
   *                  instance.
   */
  writeStrokeStyle = (olStrokeStyle: OlStyleStroke) => {
    if (!olStrokeStyle) {
      return {};
    }
    return {
      color: olStrokeStyle.getColor(),
      lineCap: olStrokeStyle.getLineCap(),
      lineJoin: olStrokeStyle.getLineJoin(),
      // If not set, getLineDash will return null.
      lineDash: olStrokeStyle.getLineDash() || undefined,
      lineDashOffset: olStrokeStyle.getLineDashOffset(),
      miterLimit: olStrokeStyle.getMiterLimit(),
      width: olStrokeStyle.getWidth(),
    };
  };
  /**
   * Returns a plain object matching the passed ol.style.Text instance.
   *
   * @param {OlStyleText} olTextStyle An ol.style.Text instance.
   * @return {Object} A plain object matching the passed `ol.style.Text`
   *                  instance.
   */
  writeTextStyle = (olTextStyle: OlStyleText) => {
    if (!olTextStyle) {
      return {};
    }

    return {
      fill: this.writeFillStyle(olTextStyle.getFill() as OlStyleFill),
      font: olTextStyle.getFont(),
      offsetX: olTextStyle.getOffsetX(),
      offsetY: olTextStyle.getOffsetY(),
      rotation: olTextStyle.getRotation(),
      scale: olTextStyle.getScale(),
      stroke: this.writeStrokeStyle(olTextStyle.getStroke() as OlStyleStroke),
      text: olTextStyle.getText(),
      textAlign: olTextStyle.getTextAlign(),
      textBaseline: olTextStyle.getTextBaseline(),
    };
  };

  /**
   * Return a plain object with style for label
   * @param {OlFeature} feature
   * @returns {Object} object with style
   *
   */
  public static makeLabel(feature: OlFeature) {
    const geom = feature.getGeometry();
    let textLabel = '';
    if (geom instanceof Polygon) {
      textLabel = PrintTools.formatArea(geom);
    } else if (geom instanceof LineString) {
      textLabel = PrintTools.formatLength(geom);
    }
    return {
      type: 'text',
      fontColor: '#4d4d4d',
      fontSize: '9px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      haloColor: 'rgba(255,255,255, 0.6)',
      haloOpacity: '0.7',
      haloRadius: 2,
      label: textLabel,
      labelAlign: 'cb',
    };
  }
} // end class
export default MapFishPrintVectorSerializer;
